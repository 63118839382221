import React from 'react'
import { StyledIntroProject } from './IntroProject.styled'
import main from '../../Assets/Img/main_signe.svg'
import { motion } from 'framer-motion'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const IntroProject = ({ title }) => {
  return (
    <StyledIntroProject>
      <div className="title">
        <div className="image">
          <motion.img
            initial={{
              opacity: 0,
              y: 60,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.3, ...transition },
            }}
            src={main}
            alt="bonhomme"
          />
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 60,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 0.3, ...transition },
          }}
          className="portfolio"
        >
          <p>portfolio</p>
        </motion.div>
        <motion.h1
          initial={{
            opacity: 0,
            y: 60,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 0.3, ...transition },
          }}
        >
          {title}
        </motion.h1>
      </div>
    </StyledIntroProject>
  )
}
