export var animationData = {
  v: '5.9.1',
  fr: 25,
  ip: 79,
  op: 230,
  w: 424,
  h: 299,
  nm: 'Précomp. 1',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Précomp. 2',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'star/star Silhouettes',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 79,
                  s: [217.943, 125.164, 0],
                  to: [47.206, 0, 0],
                  ti: [0, -23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 115.98,
                  s: [364.035, 168.572, 0],
                  to: [0, 23.974, 0],
                  ti: [21.553, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 133.082,
                  s: [306.908, 203.006, 0],
                  to: [-15.286, 0, 0],
                  ti: [80.684, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 154.464,
                  s: [217.943, 211.98, 0],
                  to: [-80.684, 0, 0],
                  ti: [0, 23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 192.562,
                  s: [71.852, 168.572, 0],
                  to: [0, -23.974, 0],
                  ti: [-33.479, 0, 0],
                },
                { t: 230, s: [217.943, 125.164, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [18, 22.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-8.464, -2.001],
                        [1.56, -8.737],
                        [0, 0],
                        [0, 0],
                        [8.915, 2.418],
                        [-1.538, 8.93],
                      ],
                      o: [
                        [0, 0],
                        [1.477, 8.571],
                        [-8.539, 2.423],
                        [0, 0],
                        [0, 0],
                        [-1.623, -9.093],
                        [8.839, -1.998],
                        [0, 0],
                      ],
                      v: [
                        [0.322, -22],
                        [1.108, -17.434],
                        [17.5, 0],
                        [0.993, 18.242],
                        [0.322, 22],
                        [-0.24, 18.853],
                        [-17.5, 0],
                        [-0.358, -18.052],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.913725550034, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [17.75, 22.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2750,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'bonhomme/star Silhouettes',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 79,
                  s: [0],
                },
                { t: 230, s: [360] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [220.943, 126.164, 0],
                  to: [80.684, 0, 0],
                  ti: [0, -23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 39.499,
                  s: [367.035, 169.572, 0],
                  to: [0, 23.974, 0],
                  ti: [80.684, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 79,
                  s: [220.943, 212.98, 0],
                  to: [-80.684, 0, 0],
                  ti: [0, 23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 115.115,
                  s: [74.852, 169.572, 0],
                  to: [0, -23.974, 0],
                  ti: [-80.684, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 151.23,
                  s: [220.943, 126.164, 0],
                  to: [80.684, 0, 0],
                  ti: [0, -23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 190.614,
                  s: [367.035, 169.572, 0],
                  to: [0, 23.974, 0],
                  ti: [80.684, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 230,
                  s: [220.943, 212.98, 0],
                  to: [-80.684, 0, 0],
                  ti: [0, 23.974, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 268.5,
                  s: [74.852, 169.572, 0],
                  to: [0, -23.974, 0],
                  ti: [-80.684, 0, 0],
                },
                { t: 307, s: [220.943, 126.164, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [36, 36, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [41.954, 43.919], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [23.938, 42.632], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45.171, 41.988], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [27.155, 40.702], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [40.667, 41.346], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.355, 0],
                        [0, -0.355],
                        [0.355, 0],
                        [0, 0.356],
                      ],
                      o: [
                        [0.355, 0],
                        [0, 0.356],
                        [-0.355, 0],
                        [0, -0.355],
                      ],
                      v: [
                        [0, -0.644],
                        [0.643, -0.001],
                        [0, 0.644],
                        [-0.643, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [22.652, 40.058], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.796, -1.663],
                        [0.711, -2.218],
                        [-0.03, -0.16],
                        [-0.081, -0.132],
                        [-0.351, -0.429],
                        [0.391, -0.15],
                        [0.044, -0.009],
                        [-0.145, -2.407],
                        [-0.398, -1.459],
                        [-0.523, -0.52],
                        [-0.743, 1.647],
                        [0.039, 1.53],
                        [0.009, 0.729],
                        [-0.568, 1.012],
                        [-0.294, 0.787],
                        [-0.138, 0.883],
                      ],
                      o: [
                        [-0.359, -2.633],
                        [-1.575, 1.484],
                        [-0.027, 0.161],
                        [0.031, 0.16],
                        [0.309, 0.472],
                        [0.293, 0.401],
                        [-0.044, 0.009],
                        [-1.863, 0.636],
                        [0.115, 1.529],
                        [0.236, 0.758],
                        [1.169, 1.241],
                        [0.628, -1.385],
                        [-0.021, -0.731],
                        [-0.023, -1.208],
                        [0.423, -0.702],
                        [0.235, -0.855],
                        [0, 0],
                      ],
                      v: [
                        [4.388, -6.709],
                        [0.153, -8.628],
                        [-3.34, -2.973],
                        [-3.336, -2.486],
                        [-3.165, -2.042],
                        [-2.174, -0.689],
                        [-2.411, 0.117],
                        [-2.542, 0.143],
                        [-4.008, 2.604],
                        [-3.236, 7.1],
                        [-2.079, 9.049],
                        [1.454, 8.229],
                        [2.139, 3.805],
                        [2.086, 1.615],
                        [2.924, -1.796],
                        [4.002, -4.036],
                        [4.406, -6.646],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [29.184, 27.08], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.796, -1.663],
                        [0.711, -2.218],
                        [-0.031, -0.16],
                        [-0.082, -0.132],
                        [-0.351, -0.429],
                        [0.391, -0.15],
                        [0.044, -0.009],
                        [-0.145, -2.407],
                        [-0.398, -1.459],
                        [-0.523, -0.52],
                        [-0.743, 1.647],
                        [0.039, 1.53],
                        [0.009, 0.729],
                        [-0.568, 1.012],
                        [-0.294, 0.787],
                        [0, 0.965],
                      ],
                      o: [
                        [-0.359, -2.633],
                        [-1.575, 1.484],
                        [-0.026, 0.161],
                        [0.03, 0.16],
                        [0.308, 0.472],
                        [0.293, 0.401],
                        [-0.044, 0.008],
                        [-1.863, 0.636],
                        [0.115, 1.529],
                        [0.236, 0.758],
                        [1.169, 1.241],
                        [0.628, -1.385],
                        [-0.021, -0.731],
                        [-0.023, -1.209],
                        [0.423, -0.702],
                        [0.235, -0.855],
                        [0, 0],
                      ],
                      v: [
                        [4.384, -6.709],
                        [0.149, -8.628],
                        [-3.344, -2.973],
                        [-3.338, -2.486],
                        [-3.168, -2.042],
                        [-2.178, -0.689],
                        [-2.414, 0.117],
                        [-2.546, 0.143],
                        [-4.012, 2.604],
                        [-3.24, 7.1],
                        [-2.082, 9.049],
                        [1.451, 8.229],
                        [2.135, 3.805],
                        [2.082, 1.615],
                        [2.92, -1.796],
                        [3.998, -4.036],
                        [4.409, -6.591],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.117647066303, 0.117647066303, 0.117647066303, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [41.727, 28.633], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.937, 1.126],
                      ],
                      o: [
                        [1.446, -0.303],
                        [0, 0],
                      ],
                      v: [
                        [-1.841, 1.104],
                        [1.841, -1.104],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.043137254902, 0.043137254902, 0.043137254902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [19.971, 45.61], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.245, -0.344],
                      ],
                      o: [
                        [0.804, 0.884],
                        [0, 0],
                      ],
                      v: [
                        [-1.573, -0.943],
                        [1.573, 0.943],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.043137254902, 0.043137254902, 0.043137254902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [50.651, 46.447], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-2.346, 3.537],
                      ],
                      o: [
                        [7.493, 0.149],
                        [0, 0],
                      ],
                      v: [
                        [-7.577, 3.65],
                        [7.577, -3.799],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.043137254902, 0.043137254902, 0.043137254902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [42.846, 50.242], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 11',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.273, 3.629],
                      ],
                      o: [
                        [-7.719, -0.153],
                        [0, 0],
                      ],
                      v: [
                        [7.651, 4.027],
                        [-7.651, -4.027],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.043137254902, 0.043137254902, 0.043137254902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [28.072, 49.874], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-16.948, 0],
                        [0, 16.947],
                        [16.947, 0],
                        [0, -16.948],
                      ],
                      o: [
                        [16.947, 0],
                        [0, -16.948],
                        [-16.948, 0],
                        [0, 16.947],
                      ],
                      v: [
                        [0, 30.686],
                        [30.686, 0],
                        [0, -30.686],
                        [-30.686, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.043137254902, 0.043137254902, 0.043137254902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.913725550034, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.686, 35.686], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.259, 0.009],
                        [4.503, -3.008],
                        [2.073, -5.003],
                        [-1.056, -5.311],
                        [-3.829, -3.83],
                        [-5.312, -1.057],
                        [-5.003, 2.072],
                        [-3.009, 4.503],
                        [0, 5.416],
                        [5.132, 5.133],
                      ],
                      o: [
                        [-5.415, -0.001],
                        [-4.503, 3.009],
                        [-2.073, 5.004],
                        [1.056, 5.312],
                        [3.829, 3.829],
                        [5.311, 1.057],
                        [5.003, -2.073],
                        [3.008, -4.502],
                        [-0.007, -7.258],
                        [-5.133, -5.133],
                      ],
                      v: [
                        [0.001, -27.378],
                        [-15.212, -22.766],
                        [-25.298, -10.479],
                        [-26.858, 5.341],
                        [-19.365, 19.362],
                        [-5.346, 26.856],
                        [10.474, 25.299],
                        [22.763, 15.214],
                        [27.377, 0.002],
                        [19.351, -19.349],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.013, -0.01],
                        [5.592, 3.736],
                        [2.574, 6.213],
                        [-1.312, 6.596],
                        [-4.755, 4.755],
                        [-6.596, 1.312],
                        [-6.213, -2.574],
                        [-3.736, -5.592],
                        [0.001, -6.725],
                        [6.373, -6.373],
                      ],
                      o: [
                        [-6.725, 0.001],
                        [-5.592, -3.736],
                        [-2.574, -6.213],
                        [1.312, -6.596],
                        [4.756, -4.756],
                        [6.596, -1.311],
                        [6.213, 2.574],
                        [3.735, 5.592],
                        [-0.011, 9.013],
                        [-6.373, 6.373],
                      ],
                      v: [
                        [0.001, 33.998],
                        [-18.89, 28.27],
                        [-31.414, 13.011],
                        [-33.35, -6.634],
                        [-24.045, -24.043],
                        [-6.635, -33.349],
                        [13.01, -31.413],
                        [28.269, -18.889],
                        [33.997, 0.002],
                        [24.028, 24.029],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Tracé 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Fusionner les tracés 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.913725550034, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fond 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [35.685, 35.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Groupe 14',
              np: 6,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2750,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Calque de forme 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [212, 149.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [292.184, 86.816], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Tracé d'ellipse 1",
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.913725550034, 0.898039275525, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Contour 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [10.045, 19.486], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transformer ',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: -37,
          op: 2713,
          st: -37,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Précomp. 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -20, ix: 10 },
        p: { a: 0, k: [192, 134, 0], ix: 2, l: 2 },
        a: { a: 0, k: [212, 149.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [122, 122, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 424,
      h: 299,
      ip: 0,
      op: 2750,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
