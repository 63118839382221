import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledRealEstates } from './RealEstates.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import Img1 from '../../Assets/Img/Yama/img-1.webp'
import Img2 from '../../Assets/Img/Yama/img-2.webp'
import Img3 from '../../Assets/Img/Yama/img-3.webp'
import Img4 from '../../Assets/Img/Yama/img-4.webp'
import Img5 from '../../Assets/Img/Yama/img-5.webp'
import video1 from '../../Assets/Img/Yama/intro.mp4'
import video2 from '../../Assets/Img/Yama/carousel.mp4'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const RealEstates = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledRealEstates>
        <ProjectNavbar />
        <IntroProject title={'Real estates'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Years
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2021-2022
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    360º Fahrenheit
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              During my apprenticeship at 360º Fahrenheit, I had the opportunity
              to create many websites for real estate developers, here are some
              examples.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <h2>YAMA</h2>
          <a
            href="https://www.yama-promotion.com/"
            className="website-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            view online
          </a>
          <div className="image mb">
            <video
              src={video1}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="image mb">
            <img src={Img1} alt="bonhomme" />
          </div>
          <div className="image">
            <video
              src={video2}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>Canal en Seine</h2>
          <a
            href="https://www.canal-en-seine.fr/"
            className="website-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            view online
          </a>
          <div className="image">
            <img src={Img2} alt="bonhomme" />
          </div>
          <h2>TechForBuilding</h2>
          <a
            href="https://www.techforbuildings.com"
            className="website-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            view online
          </a>
          <div className="image">
            <img src={Img3} alt="bonhomme" />
          </div>
          <h2>L&apos;écrin du parc</h2>
          <a
            href="https://www.l-ecrin-du-parc-arpajon.fr/"
            className="website-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            view online
          </a>
          <div className="image">
            <img src={Img4} alt="bonhomme" />
          </div>
          <h2>Le Carré des Bourguignons</h2>
          <a
            href="https://www.carre-bourguignons-asnieres.fr/"
            className="website-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            view online
          </a>
          <div className="image">
            <img src={Img5} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledRealEstates>
    </ThemeProvider>
  )
}
