import React from 'react'
import { StyledHoverImage } from './HoverImage.styled'

// import Code from '../../Assets/Img/code.gif'
// import Coucou from '../../Assets/Img/coucou.gif'

export const HoverImage = ({ active, xPos, yPos, page }) => {
  const image =
    page == 'intro' ? (
      <iframe
        src="https://giphy.com/embed/QuIxFwQo0RMT1tASlV"
        width="480"
        height="270"
        title="Gif développeur"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    ) : (
      <iframe
        src="https://giphy.com/embed/QLKSt3wQqlj7a"
        width="480"
        height="240"
        frameBorder="0"
        title="Gif coucou"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    )
  return (
    <StyledHoverImage active={active} xPos={xPos} yPos={yPos}>
      <div className="image">
        {image}
        {/*<img src={image} alt="bonhomme" />*/}
      </div>
    </StyledHoverImage>
  )
}
