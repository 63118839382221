import { createGlobalStyle } from 'styled-components'

import MonumentBlackOtf from '../Assets/Fonts/MonumentExtended-Black.otf'
import MonumentBlackTtf from '../Assets/Fonts/MonumentExtended-Black.ttf'
import MonumentRegularOtf from '../Assets/Fonts/MonumentExtended-Regular.otf'
import MonumentRegularTtf from '../Assets/Fonts/MonumentExtended-Regular.ttf'
import MonumentLightOtf from '../Assets/Fonts/MonumentExtended-Light.otf'
import MonumentLightTtf from '../Assets/Fonts/MonumentExtended-Light.ttf'
import Avenir from '../Assets/Fonts/Avenir-Light-07.ttf'
import AvenirBookOblique from '../Assets/Fonts/Avenir-BookOblique-02.ttf'
import AvenirHeavy from '../Assets/Fonts/Avenir-Heavy-05.ttf'
import AvenirBook from '../Assets/Fonts/Avenir-Book-01.ttf'

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: "Monument";
    font-style: normal;
    font-weight: 900;
    src: url(${MonumentBlackOtf}) format("otf"),
    url(${MonumentBlackTtf}) format("truetype");
  }
  @font-face {
    font-family: "Monument";
    font-style: normal;
    font-weight: normal;
    src: url(${MonumentRegularOtf}) format("otf"),
    url(${MonumentRegularTtf}) format("truetype");
  }
  @font-face {
    font-family: "Monument";
    font-style: normal;
    font-weight: 300;
    src: url(${MonumentLightOtf}) format("otf"),
    url(${MonumentLightTtf}) format("truetype");
  }
  @font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: normal;
    src: url(${Avenir}) format("truetype");
  }
  @font-face {
    font-family: "AvenirBO";
    font-style: normal;
    font-weight: normal;
    src: url(${AvenirBookOblique}) format("truetype");
  }
  @font-face {
    font-family: "AvenirBook";
    font-style: normal;
    font-weight: normal;
    src: url(${AvenirBook}) format("truetype");
  }
  @font-face {
    font-family: "AvenirHeavy";
    font-style: normal;
    font-weight: normal;
    src: url(${AvenirHeavy}) format("truetype");
  }
`

export default GlobalFonts
