import React, { useRef, useEffect, useState } from 'react'
import { StyledNavbar } from './Navbar.styled'
import { gsap } from 'gsap'
import { animationLogo } from './json'
import Lottie from 'lottie-web'

export const Navbar = ({ handleNav, handleNavigationMob }) => {
  const link1 = useRef()
  const link2 = useRef()
  const link3 = useRef()
  const link4 = useRef()
  const link5 = useRef()
  const linkMob1 = useRef()
  const linkMob2 = useRef()
  const linkMob3 = useRef()
  const linkMob4 = useRef()
  const linkMob5 = useRef()
  const menuTop = useRef()
  const menuMid = useRef()
  const menuBot = useRef()
  const containerMenu = useRef()
  const logo = useRef()
  const [anim, setAnim] = useState()

  const tl = gsap.timeline({ paused: true, reversed: true })
  useEffect(() => {
    tl.to(
      menuTop.current,
      { y: '-9px', transformOrigin: '50% 50%', duration: 0.2 },
      'burg'
    )
      .to(
        menuBot.current,
        { y: '9px', transformOrigin: '50% 50%', duration: 0.2 },
        'burg'
      )
      .to(
        menuMid.current,
        { scale: 0.1, transformOrigin: '50% 50%', duration: 0.2 },
        'burg'
      )
      .add('rotate')
      .to(menuTop.current, { y: '7', duration: 0.2 }, 'rotate')
      .to(menuBot.current, { y: '-7', duration: 0.2 }, 'rotate')
      .to(
        menuTop.current,
        { rotationZ: 45, transformOrigin: '50% 50%', duration: 0.2 },
        'rotate'
      )
      .to(
        menuBot.current,
        { rotationZ: -45, transformOrigin: '50% 50%', duration: 0.2 },
        'rotate'
      )
      .to(containerMenu.current, {
        x: 0,
        opacity: 1,
        duration: 0.5,
      })
      .fromTo(
        linkMob1.current,
        { top: 60 },
        {
          top: 0,
          duration: 0.3,
        }
      )
      .fromTo(
        linkMob2.current,
        { top: 60 },
        {
          top: 0,
          duration: 0.3,
        }
      )
      .fromTo(
        linkMob5.current,
        { top: 60 },
        {
          top: 0,
          duration: 0.3,
        }
      )
      .fromTo(
        linkMob3.current,
        { top: 60 },
        {
          top: 0,
          duration: 0.3,
        }
      )
      .fromTo(
        linkMob4.current,
        { top: 60 },
        {
          top: 0,
          duration: 0.3,
        }
      )
  })
  useEffect(() => {
    var params = {
      container: logo.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: animationLogo,
    }
    setAnim(Lottie.loadAnimation(params))
  }, [])
  const activeAnim = () => {
    anim.play()
  }
  const unactiveAnim = () => {
    anim.stop()
  }
  const menuToggle = () => {
    console.log('toggle')
    tl.reversed() ? tl.restart() : tl.reverse()
  }
  return (
    <StyledNavbar className="NavBar">
      <div
        className="logo_nav"
        onMouseEnter={activeAnim}
        onMouseLeave={unactiveAnim}
        onClick={() => handleNav(link1)}
      >
        <p>
          jérémy
          <br />
          lichtmann
        </p>
        <div ref={logo}></div>
      </div>
      <div className="links">
        <p ref={link1} onClick={() => handleNav(link1)}>
          Home
        </p>
        <p ref={link2} onClick={() => handleNav(link2)}>
          About
        </p>
        <p ref={link5} onClick={() => handleNav(link5)}>
          Work
        </p>
        <p ref={link3} onClick={() => handleNav(link3)}>
          Profile
        </p>
        <p ref={link4} onClick={() => handleNav(link4)}>
          Contact
        </p>
      </div>
      <div className="mobile">
        <div className="icon" onClick={menuToggle}>
          <svg
            id="burger"
            width="30"
            className="openmenu"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
          >
            <path ref={menuTop} d="M0 7h30v0H0z" strokeWidth="2" />
            <line
              ref={menuMid}
              x1="0"
              y1="14"
              x2="30"
              y2="14"
              strokeWidth="2"
              vectorEffect="non-scaling-stroke"
            />
            <path ref={menuBot} d="M0 21h30v0H0z" strokeWidth="2" />
          </svg>
        </div>
        <div className="menu-overlay" ref={containerMenu}>
          <div className="menu-content">
            <ul className="menu-list">
              <li className="menu-link">
                <p
                  ref={linkMob1}
                  onClick={() => {
                    tl.reverse(), handleNavigationMob('Home')
                  }}
                >
                  Home
                </p>
              </li>
              <li className="menu-link">
                <p
                  ref={linkMob2}
                  onClick={() => {
                    tl.reverse(), handleNavigationMob('About')
                  }}
                >
                  About
                </p>
              </li>
              <li className="menu-link">
                <p
                  ref={linkMob5}
                  onClick={() => {
                    tl.reverse(), handleNavigationMob('Work')
                  }}
                >
                  Work
                </p>
              </li>
              <li className="menu-link">
                <p
                  ref={linkMob3}
                  onClick={() => {
                    tl.reverse(), handleNavigationMob('Profile')
                  }}
                >
                  Profile
                </p>
              </li>
              <li className="menu-link">
                <p
                  ref={linkMob4}
                  onClick={() => {
                    tl.reverse(), handleNavigationMob('Contact')
                  }}
                >
                  Contact
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledNavbar>
  )
}
