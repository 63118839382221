import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin'
import { StyledDontHover } from './DontHover.styled'
import face from '../../Assets/Img/face.svg'

gsap.registerPlugin(Physics2DPlugin)

export const DontHover = () => {
  const container = useRef()

  let dots = []
  let i, dot

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      for (i = 0; i < 80; i++) {
        dot = document.createElement('div')
        dot.setAttribute('class', 'dot')

        container.current.appendChild(dot)

        dots[i] = dot
      }
    }
    return () => {
      isMounted = false
    }
  }, [container, dots])
  let throttleTimer
  const throttle = (callback, time = 5600) => {
    if (throttleTimer) return
    throttleTimer = true
    callback()
    setTimeout(() => {
      throttleTimer = false
    }, time)
  }
  const anim = () => {
    gsap.set(dots, {
      scale: 'random(0.4, 1)',
      x: 0,
      y: 0,
    })
    gsap.to(dots, {
      duration: 5.5,
      physics2D: {
        velocity: 'random(200, 650)',
        angle: 'random(250, 290)',
        gravity: 500,
      },
      delay: 'random(0, 5.5)',
    })
  }
  const throttledAim = () => {
    throttle(anim)
  }

  return (
    <StyledDontHover className="DontHover" image={face}>
      <div className="img" ref={container} onMouseEnter={throttledAim}>
        <img src={face} alt="bonhomme" />
      </div>
      <div className="text">
        <h1>hey.&nbsp;</h1>
        <div className="gradient" onMouseEnter={throttledAim}>
          <h1>Don&apos;t hover</h1>
          <div className="line"></div>
        </div>
        <h1>&nbsp;me</h1>
      </div>
    </StyledDontHover>
  )
}
