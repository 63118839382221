import React from 'react'
import { Link } from 'react-router-dom'
import { StyledProject } from './Project.styled'

export const Project = ({ title, image, technos, link }) => {
  return (
    <StyledProject className="Project">
      <div className="title">
        <Link to={`/${link}`}>
          <h2>{title}</h2>
        </Link>
      </div>
      <div className="vue">
        <Link to={`/${link}`}>
          <div className="image">
            <img src={image} alt="bonhomme" />
          </div>
        </Link>
        <div className="techno">
          <p>{technos}</p>
        </div>
      </div>
    </StyledProject>
  )
}
