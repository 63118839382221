import styled from 'styled-components'
import face from '../../Assets/Img/face.svg'

export const StyledDontHover = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: none;
  }

  .img {
    position: relative;
    width: 110px;
    margin-bottom: 40px;

    img {
      position: relative;
      z-index: 99;
    }
  }
  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-family: 'Monument', sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: normal;
    }
    .gradient {
      position: relative;
      width: fit-content;
      overflow: hidden;
      cursor: pointer;
      h1 {
        width: fit-content;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 0;
      }
      .line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6px;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        transition: all 0.3s ease-in-out;
        transform: translateX(-105%);
      }
      &:hover {
        .line {
          transform: translateX(0);
        }
      }
    }
  }
  .dot {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-image: url(${face});
    background-repeat: no-repeat;
    background-size: cover;
    width: 90px;
    height: 90px;
    pointer-events: none;
    will-change: transform;
  }
`
