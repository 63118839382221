import styled from 'styled-components'

export const StyledRealEstates = styled.div`
  width: 100%;
  padding-bottom: 150px;

  .website-link {
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 15px;
    padding: 5px 15px 3px 15px;
    margin-top: -20px;
    margin-bottom: 50px;
  }
  img {
    width: 100%;
    height: auto;
  }
`
