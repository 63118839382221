import React from 'react'
import { forwardRef, useImperativeHandle, useEffect } from 'react'
import { StyledWork } from './Work.styled'
import { gsap } from 'gsap'
import main from '../../Assets/Img/main_signe.svg'

const Work = (props, ref) => {
  var tl = gsap.timeline({})
  var tl2 = gsap.timeline({})
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      tl.to('#up-1', { duration: 0.1, opacity: 1 })
        .to('#up-2', { duration: 0.1, opacity: 1 })
        .to('#up-3', { duration: 0.1, opacity: 1 })
        .to('#up-4', { duration: 0.1, opacity: 1 })
        .to('#up-5', { duration: 0.1, opacity: 1 })
        .to('#full', { duration: 0.1, opacity: 1 })
        .to('#up-1', { duration: 0.1, opacity: 0 })
        .to('#up-2', { duration: 0.1, opacity: 0 })
        .to('#up-3', { duration: 0.1, opacity: 0 })
        .to('#up-4', { duration: 0.1, opacity: 0 })
        .to('#up-5', { duration: 0.1, opacity: 0 })

      tl2
        .to('#dwn-5', { duration: 0.1, opacity: 1 })
        .to('#dwn-4', { duration: 0.1, opacity: 1 })
        .to('#dwn-3', { duration: 0.1, opacity: 1 })
        .to('#dwn-2', { duration: 0.1, opacity: 1 })
        .to('#dwn-1', { duration: 0.2, opacity: 1 })
        .to('#dwn-5', { duration: 0.1, opacity: 0 })
        .to('#dwn-4', { duration: 0.1, opacity: 0 })
        .to('#dwn-3', { duration: 0.1, opacity: 0 })
        .to('#dwn-2', { duration: 0.1, opacity: 0 })
        .to('#dwn-1', { duration: 0.1, opacity: 0 })
    }
    return () => {
      isMounted = false
    }
  }, [])
  useImperativeHandle(
    ref,
    () => ({
      activeAnim(status) {
        if (status === false) {
          tl.restart()
          tl2.restart()
        } else {
          tl.reverse()
          tl2.reverse()
        }
      },
    }),
    []
  )
  return (
    <StyledWork>
      <div className="image">
        <img src={main} alt="bonhomme" />
      </div>
      <div className="portfolio">
        <p>portfolio</p>
      </div>
      <div className="title">
        <span className="content__text" id="up-1">
          <span className="content__text-inner content__text-inner--stroke">
            Selected work
          </span>
        </span>
        <span className="content__text" id="up-2">
          <span className="content__text-inner">Selected work</span>
        </span>
        <span className="content__text content__text--full" id="up-3">
          <span className="content__text-inner content__text-inner--stroke">
            Selected work
          </span>
        </span>
        <span className="content__text" id="up-4">
          <span className="content__text-inner content__text-inner--stroke">
            Selected work
          </span>
        </span>
        <span className="content__text" id="up-5">
          <span className="content__text-inner">Selected work</span>
        </span>
        <span className="content__text content__text--full" id="full">
          <span className="content__text-inner">Selected work</span>
        </span>
        <span className="content__text" id="dwn-1">
          <span className="content__text-inner content__text-inner--bottom">
            Selected work
          </span>
        </span>
        <span className="content__text" id="dwn-2">
          <span className="content__text-inner content__text-inner--bottom">
            Selected work
          </span>
        </span>
        <span className="content__text content__text--full" id="dwn-3">
          <span className="content__text-inner content__text-inner--stroke">
            Selected work
          </span>
        </span>
        <span className="content__text" id="dwn-4">
          <span className="content__text-inner content__text-inner--stroke content__text-inner--bottom">
            Selected work
          </span>
        </span>
        <span className="content__text" id="dwn-5">
          <span className="content__text-inner content__text-inner--bottom">
            Selected work
          </span>
        </span>
      </div>
    </StyledWork>
  )
}

export default forwardRef(Work)
