import styled from 'styled-components'

export const StyledContactMe = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    display: none;
  }

  p {
    font-size: 16px;
    font-family: 'AvenirBook', sans-serif;
  }
  .contact_me {
    position: relative;
    width: 145px;
    height: 35px;
    margin-left: 20px;
    border: 1px solid;
    border-radius: 41px;
    overflow: hidden;

    a {
      text-decoration: none;
      padding: 5px;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: flex-start;
      width: 145px;
      height: 35px;
      white-space: nowrap;
      transition: all 1s ease;
      overflow: hidden;

      p {
        font-family: 'AvenirBook', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        margin-right: 15px;
        display: flex;
        animation: scrollText 5s infinite linear;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @keyframes scrollText {
    from {
      transform: translateX(-67px);
    }
    to {
      transform: translateX(-160px);
    }
  }
`
