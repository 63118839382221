import React, { useState, useEffect } from 'react'
import { StyledToggleDarkMode, StyledPuce } from './ToggleDarkMode.styled'

// eslint-disable-next-line react/prop-types
export const ToggleDarkMode = ({ sendToHome }) => {
  const [theme, setTheme] = useState('dark')

  const setMode = (mode) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
    sendToHome(mode)
  }

  const toggleTheme = () => {
    theme === 'dark' ? setMode('light') : setMode('dark')
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const localTheme = window.localStorage.getItem('theme')
      localTheme
        ? (setTheme(localTheme), sendToHome(localTheme))
        : setMode('dark')
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <StyledToggleDarkMode onClick={toggleTheme}>
      {theme === 'light' ? (
        <div className="toggleTheme">
          <p>Dark mode</p>
          <div className="button">
            <StyledPuce className="puce" pos="left" />
          </div>
        </div>
      ) : (
        <div className="toggleTheme">
          <p>Light mode</p>
          <div className="button">
            <StyledPuce className="puce" pos="right" />
          </div>
        </div>
      )}
    </StyledToggleDarkMode>
  )
}
