import styled from 'styled-components'

export const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 1024px) {
    padding-bottom: 150px;
  }
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5%;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 768px) {
        align-items: center;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 768px) {
          align-items: center;
        }

        h1 {
          font-family: 'Monument', sans-serif;
          font-size: 120px;

          @media (max-width: 1024px) {
            font-size: 70px;
          }
          @media (max-width: 768px) {
            font-size: 45px;
          }
          @media (max-width: 450px) {
            font-size: 30px;
          }
        }

        .first {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          @media (max-width: 768px) {
            flex-direction: column-reverse;
            justify-content: flex-start;
          }

          .image {
            position: relative;
            width: 150px;
            height: 150px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: 1024px) {
              width: 100px;
              height: 100px;
              margin-bottom: 20px;
              margin-left: 30px;
            }
            @media (max-width: 768px) {
              margin-left: 0;
            }

            .center {
              position: relative;
              z-index: 2;
              width: 75px;
              left: 4px;

              @media (max-width: 1024px) {
                width: 55px;
              }
            }
            .bg {
              position: absolute;
              z-index: 1;
              width: 100%;
              top: 0;
              left: 0;
              animation: rotateImageContact 10s infinite linear;
            }
          }
          @keyframes rotateImageContact {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }

      .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 150px;

        @media (max-width: 1024px) {
          margin-top: 100px;
        }
        @media (max-width: 768px) {
          flex-direction: column;
        }

        .gradient {
          position: relative;
          width: fit-content;
          overflow: hidden;
          cursor: pointer;
          padding-bottom: 10px;

          &:last-child {
            margin-left: 80px;

            @media (max-width: 768px) {
              margin-left: 0;
              margin-top: 50px;
            }
          }

          a {
            font-family: 'Monument', sans-serif;
            font-size: 35px;
            text-decoration: none;
            width: fit-content;
            background-image: linear-gradient(
              98deg,
              #7673eb 9%,
              #77c0de 60%,
              #78fad3 93%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 0;

            @media (max-width: 1024px) {
              font-size: 22px;
            }
            @media (max-width: 450px) {
              font-size: 20px;
            }
          }
          .line {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 6px;
            background: -webkit-linear-gradient(
              -15deg,
              rgb(29, 3, 108),
              rgb(0, 222, 199)
            );
            transition: all 0.3s ease-in-out;
            transform: translateX(-105%);
          }
          &:hover {
            .line {
              transform: translateX(0);
            }
          }
        }
      }
    }

    .image-vert {
      width: 130px;

      @media (max-width: 1024px) {
        width: 90px;
      }
      @media (max-width: 768px) {
        transform: rotate(-90deg);
      }
    }
  }
  img,
  svg {
    width: 100%;
    height: auto;
  }
`
