import styled from 'styled-components'

export const StyledExperience = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    margin: 150px auto;
    border: none;
  }

  .title {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 35vh;

    @media (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 150px;
    }
    h1 {
      position: relative;
      font-family: 'Monument', sans-serif;
      font-size: 50px;
      z-index: 11;

      @media (max-width: 1024px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 25px;
      }

      &:last-of-type {
        z-index: 9;
      }
    }
    .image {
      position: absolute;
      z-index: 10;
      width: 360px;

      @media (max-width: 768px) {
        width: 280px;
      }
    }
  }
  .items {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    left: 0;
    right: 0;
    bottom: calc((100% - 927px) / 2);

    @media (max-width: 1350px) {
      bottom: calc((100% - 868px) / 2);
    }

    @media (max-width: 1024px) {
      position: relative;
      bottom: 0;
      flex-direction: column;
      align-items: center;
    }

    .line-begin {
      margin-top: 43px;
      width: 12%;
      height: 2px;

      @media (max-width: 1350px) {
        margin-top: 38px;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .item-desk {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 22%;

      @media (max-width: 1024px) {
        display: none;
      }

      .date {
        position: relative;
        width: fit-content;
        overflow: hidden;
        cursor: pointer;

        h1 {
          font-family: 'Monument', sans-serif;
          font-size: 23px;
          width: fit-content;
          background-image: linear-gradient(
            98deg,
            #7673eb 9%,
            #77c0de 60%,
            #78fad3 93%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 0;

          @media (max-width: 1350px) {
            font-size: 18px;
          }
        }
      }
      .features {
        position: relative;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 100%;

        .circle {
          position: absolute;
          top: calc(50% - 5px);
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .line {
          width: 100%;
          height: 2px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 10px;
        max-width: 245px;

        h2 {
          font-family: 'Monument', sans-serif;
          font-size: 16px;
          line-height: 120%;

          @media (max-width: 1350px) {
            font-size: 14px;
          }
        }
        p {
          font-family: 'AvenirBO', sans-serif;
          font-size: 13px;
          margin-top: 22px;
          line-height: 120%;
        }
      }
    }
    .item-mob {
      display: none;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 40%;
      height: 200px;

      @media (max-width: 1024px) {
        display: flex;
      }
      @media (max-width: 768px) {
        width: 70%;
      }

      .features {
        position: relative;
        margin-right: 20px;
        height: 100%;

        .circle {
          position: absolute;
          top: 46px;
          left: -4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .line {
          height: 100%;
          width: 2px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .date {
          position: relative;
          width: fit-content;
          overflow: hidden;
          cursor: pointer;

          h1 {
            font-family: 'Monument', sans-serif;
            font-size: 35px;
            width: fit-content;
            background-image: linear-gradient(
              98deg,
              #7673eb 9%,
              #77c0de 60%,
              #78fad3 93%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 0;

            @media (max-width: 768px) {
              font-size: 25px;
            }
            @media (max-width: 450px) {
              font-size: 20px;
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          h2 {
            font-family: 'Monument', sans-serif;
            font-size: 16px;
            margin-top: 10px;
          }
          p {
            font-family: 'AvenirBO', sans-serif;
            font-size: 13px;
            margin-top: 15px;
          }
        }
      }
    }
    .big-item {
      @media (max-width: 768px) {
        height: 250px;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`
