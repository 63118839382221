import styled from 'styled-components'

export const StyledCurri = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    margin-bottom: 150px;
  }

  h2 {
    font-family: 'Monument', sans-serif;
    font-size: 35px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
  h3 {
    font-family: 'Avenir', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 160%;
  }
  p {
    font-family: 'Avenir', sans-serif;
    font-size: 13px;
    line-height: 150%;
  }

  .container-curri {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid;
    border-bottom: 2px solid;

    @media (max-width: 1024px) {
      border: none;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  .skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 450px;

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .lang-interest {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-left: solid 2px;

    @media (max-width: 1024px) {
      border: none;
    }

    .lang,
    .interest {
      width: 100%;
      height: 50%;
      max-width: 450px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: 1024px) {
        margin-top: 80px;
      }
    }
    .line {
      width: 100%;
      height: 2px;

      @media (max-width: 1024px) {
        display: none;
      }
    }
    .interest {
      position: relative;

      .kiteHover {
        cursor: default;
        &:hover {
          font-family: 'AvenirHeavy', sans-serif;
        }
      }
      .kite {
        display: ${(props) => (props.displayKite ? 'block' : 'none')};
        position: absolute;
        width: 300px;
        right: 80px;
        top: 45%;

        iframe {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  img,
  svg {
    width: 100%;
    height: auto;
  }
`
