import React from 'react'
import { StyledExperience } from './Experience.styled'
import { OrbitAnimation } from '../../Components/OrbitAnimation/OrbitAnimation'

export const Experience = () => {
  return (
    <StyledExperience className="Experience">
      <div className="title">
        <h1>Exper</h1>
        <h1>ience</h1>
        <div className="image">
          <OrbitAnimation />
        </div>
      </div>
      <div className="items">
        <div className="line-begin"></div>
        <div className="item-desk">
          <div className="date">
            <h1>2012-2015</h1>
          </div>
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="text">
            <h2>Classes Préparatoire aux Grandes Écoles</h2>
            <p>Physics &amp; Engineering Sciences</p>
          </div>
        </div>
        <div className="item-desk">
          <div className="date">
            <h1>2015-2018</h1>
          </div>
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="text">
            <h2>Computer engineering school</h2>
            <p>
              Specialized in automation and embedded systems
              <br />
              Projects: Data acquisition system for an evolving connected
              beehive, programmed in Python
              <br />
              Programming of a LegoNXT robot, programmed with MatLab
            </p>
          </div>
        </div>
        <div className="item-desk">
          <div className="date">
            <h1>2018-2021</h1>
          </div>
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="text">
            <h2>
              Master - <nobr>Digital Architect</nobr>
            </h2>
            <p>Specialized in web development</p>
          </div>
        </div>
        <div className="item-desk">
          <div className="date">
            <h1>2019-2021</h1>
          </div>
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="text">
            <h2>Starting apprenticeship at 360° fahrenheit </h2>
            <p>
              Frontend and backend developer
              <br />
              Server and hosting management
            </p>
          </div>
        </div>
        <div className="item-desk">
          <div className="date">
            <h1>2021-2022</h1>
          </div>
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="text">
            <h2>Freelancer</h2>
            <p>
              Frontend and backend developer
              <br />
              Server and hosting management
            </p>
          </div>
        </div>

        <div className="item-mob">
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="date">
              <h1>2012-2015</h1>
            </div>
            <div className="text">
              <h2>Classe Préparatoire aux Grandes Écoles</h2>
              <p>Physics &amp; Engineering Sciences</p>
            </div>
          </div>
        </div>
        <div className="item-mob big-item">
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="date">
              <h1>2015-2018</h1>
            </div>
            <div className="text">
              <h2>Computer engineering school</h2>
              <p>
                Specialized in automation and embedded systems
                <br />
                Projects: Data acquisition system for an evolving connected
                beehive, programmed in Python
                <br />
                Programming of a LegoNXT robot, programmed with MatLab
              </p>
            </div>
          </div>
        </div>
        <div className="item-mob">
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="date">
              <h1>2018-2021</h1>
            </div>
            <div className="text">
              <h2>
                Master - <nobr>Digital Architect</nobr>
              </h2>
              <p>Specialized in web development</p>
            </div>
          </div>
        </div>
        <div className="item-mob">
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="date">
              <h1>2019-2021</h1>
            </div>
            <div className="text">
              <h2>Starting apprenticeship at 360° fahrenheit </h2>
              <p>
                Frontend and backend developer
                <br />
                Server and hosting management
              </p>
            </div>
          </div>
        </div>
        <div className="item-mob">
          <div className="features">
            <div className="circle"></div>
            <div className="line"></div>
          </div>
          <div className="content">
            <div className="date">
              <h1>2021-2022</h1>
            </div>
            <div className="text">
              <h2>Freelancer</h2>
              <p>
                Frontend and backend developer
                <br />
                Server and hosting management
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledExperience>
  )
}
