import styled from 'styled-components'

export const StyledProject = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 150px;

  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }
  @media (max-width: 768px) {
    margin-bottom: 75px;
    flex-direction: column-reverse;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  a {
    text-decoration: none;
  }

  .title {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      font-family: 'Monument', sans-serif;
      font-size: 40px;
      font-style: normal;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 30px;
        text-align: center;
      }
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }
  .vue {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:hover {
      .image {
        img {
          transform: scale(1.05);
        }
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }

    .image {
      width: 100%;
      overflow: hidden;

      img {
        transition: all 0.5s ease-in-out;
      }
    }
    .techno {
      margin-top: 5px;

      p {
        font-family: 'Avenir', sans-serif;
        font-size: 15px;
        font-style: normal;

        @media (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
  .title:hover + .vue .image img {
    transform: scale(1.05);
  }
  img {
    width: 100%;
    height: auto;
  }
`
