import styled from 'styled-components'

export const StyledNavbar = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 0 30px;

  @media (max-width: 1024px) {
    padding: 20px 30px 0 10px;
  }

  .logo_nav {
    position: relative;
    width: 175px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1024px) {
      width: 120px;
    }

    p {
      position: absolute;
      font-family: 'Monument', sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 8px;
      }
    }
    svg {
      width: 100%;
    }

    .circle_one {
      position: absolute;
      left: 20px;
      width: calc(100% - 50px);
      border: 1px solid;
      height: 100%;
      border-radius: 50% 50%;
    }
    .circle_two {
      position: absolute;
      right: 20px;
      width: calc(100% - 50px);
      border: 1px solid;
      height: 100%;
      border-radius: 50% 50%;
    }
  }
  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1024px) {
      display: none;
    }

    a,
    p {
      font-family: 'Avenir', sans-serif;
      font-size: 13px;
      font-weight: normal;
      text-decoration: none;
      padding: 5px 15px;
      cursor: pointer;
    }
  }
  .mobile {
    position: relative;
    z-index: 9999;
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }

    .icon {
      position: relative;
      z-index: 999;
      width: 60px;
      padding: 8px 15px;
      cursor: pointer;

      svg {
        perspective: 800px;
      }
    }
    .menu-overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: auto;
      bottom: 0;
      z-index: 99;
      width: 100%;
      transform: translateX(-110%);
      opacity: 0;

      .menu-content {
        width: 100%;
        height: 100%;

        overflow-x: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transition-delay: 0.1s;

        .menu-list {
          list-style: none;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          li {
            width: fit-content;
            overflow: hidden;
            padding: 10px;
            margin-bottom: 20px;
            overflow: hidden;

            a,
            p {
              position: relative;
              font-family: 'Monument', sans-serif;
              font-size: 40px;
              text-decoration: none;
              cursor: pointer;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`
