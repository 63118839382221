import styled from 'styled-components'

export const StyledToggleDarkMode = styled.div`
  position: fixed;
  bottom: 30px;
  left: 50px;
  z-index: 1000;

  @media (max-width: 1024px) {
    display: none;
  }

  .toggleTheme {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    p {
      font-size: 15px;
      font-family: 'AvenirBook', sans-serif;
    }
    .button {
      width: 50px;
      height: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-left: 20px;
      border-radius: 20px;

      .puce {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }
`
export const StyledPuce = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translateX(${(props) => (props.pos === 'left' ? '2' : '28')}px);
`
