import styled from 'styled-components'

export const StyledOrbitAnimation = styled.div`
  width: 100%;

  svg {
    width: 100%;
    height: auto;
  }
`
