import React from 'react'
import { StyledContact } from './Contact.styled'
import centerPatch from '../../Assets/Img/main_signe.svg'
import bgPatch from '../../Assets/Img/bg-looking.svg'
//import circlesmiley from '../../Assets/Img/circlesmiley.svg'

export const Contact = () => {
  return (
    <StyledContact className="Contact">
      <div className="content">
        <div className="left">
          <div className="title">
            <div className="first">
              <h1>Jérémy</h1>
              <div className="image">
                <div className="center">
                  <img src={centerPatch} alt="bonhomme" />
                </div>
                <div className="bg">
                  <img src={bgPatch} alt="bonhomme" />
                </div>
              </div>
            </div>
            <h1>Lichtmann</h1>
          </div>
          <div className="links">
            <div className="gradient">
              <a
                href="mailto:jeremy.lichtmann@gmail.com"
                rel="noopener noreferrer"
              >
                Contact me
              </a>
              <div className="line"></div>
            </div>
            <div className="gradient">
              <a href="/CV_Lichtmann_Jeremy.pdf" download>
                Download my CV
              </a>
              <div className="line"></div>
            </div>
          </div>
        </div>
        <div className="image-vert">
          <svg
            width="135"
            height="401"
            viewBox="0 0 135 401"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#kdalb2tmba)">
              <path
                d="M7.338 67c0 33.399 27.075 60.475 60.474 60.475 33.4 0 60.475-27.076 60.475-60.475 0-33.4-27.075-60.475-60.475-60.475C34.413 6.525 7.338 33.601 7.338 67z"
                fill="#F4F2F0"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                className="path-smiley"
                d="M35.514 71.28c-1.059-15.56 6.597-27 13.496-32.215"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                className="path-smiley"
                d="M35.452 70.363c1.028 15.102 10.14 25.08 17.674 29.174"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                className="path-smiley"
                d="M54.776 96.663c-1.637 1.774-2.766 4.097-3.238 6.664M46.861 34.608a13.185 13.185 0 0 0 5.092 7.018"
                stroke="#0B0B0B"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                className="path-smiley"
                d="M101.692 86.517c5.234-1.189 6.774-5.541 3.112-8.861-3.265-2.906-7.41-4.988-11.997-6.027a2.843 2.843 0 0 0-.98.098c-.317.089-.61.232-.863.42a31.6 31.6 0 0 0-2.546 2.232c-.756.661-1.292.428-1.662-.332l-.075-.26c-1.61-3.637-.397-3.231-5.213-2.514a48.133 48.133 0 0 0-8.91 2.35c-1.483.607-2.764 1.53-3.717 2.675-2.29 2.571-1.168 5.759 2.277 6.963 2.898 1.017 5.95.942 9.022.592 1.466-.17 2.931-.347 4.396-.495 2.427-.26 4.874.119 7.011 1.084a27.182 27.182 0 0 0 4.698 1.772c1.762.322 3.272.545 5.214.374l.233-.071zM102.597 61.012c5.234-1.188 6.774-5.541 3.112-8.86-3.265-2.907-7.41-4.989-11.997-6.027a2.845 2.845 0 0 0-.98.097c-.317.09-.61.232-.863.42a31.617 31.617 0 0 0-2.546 2.233c-.756.66-1.292.428-1.662-.333l-.075-.26c-1.61-3.636-.397-3.23-5.214-2.513a48.132 48.132 0 0 0-8.91 2.35c-1.482.607-2.763 1.529-3.716 2.675-2.29 2.57-1.168 5.758 2.277 6.963 2.898 1.017 5.95.941 9.022.591 1.466-.17 2.931-.346 4.396-.494 2.427-.26 4.874.118 7.011 1.083a27.183 27.183 0 0 0 4.698 1.772c1.762.322 3.523.229 5.324.35l.123-.047z"
                fill="#1E1E1E"
              />
              <circle
                cx="61.053"
                cy="42.527"
                transform="rotate(84.97 61.053 42.527)"
                fill="#1E1E1E"
                r="1.3"
              />
              <circle
                cx="61.654"
                cy="79.003"
                transform="rotate(84.97 61.654 79.003)"
                fill="#1E1E1E"
                r="1.3"
              />
              <circle
                cx="60.556"
                cy="51.703"
                transform="rotate(84.97 60.556 51.703)"
                fill="#1E1E1E"
                r="1.3"
              />
              <circle
                cx="61.157"
                cy="88.178"
                r="1.3"
                transform="rotate(84.97 61.157 88.178)"
                fill="#1E1E1E"
              />
              <circle
                cx="56.102"
                cy="45.572"
                transform="rotate(84.97 56.102 45.572)"
                fill="#1E1E1E"
                r="1.3"
              />
              <circle
                cx="56.703"
                cy="82.047"
                transform="rotate(84.97 56.703 82.047)"
                fill="#1E1E1E"
                r="1.3"
              />
            </g>
            <path
              d="M7.812 188.5c0 33.413 26.863 60.5 60 60.5s60-27.087 60-60.5-26.863-60.5-60-60.5-60 27.087-60 60.5z"
              stroke="#0B0B0B"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M7.812 188.5c0 11.322 27.087 20.5 60.5 20.5 33.414 0 60.5-9.178 60.5-20.5s-27.086-20.5-60.5-20.5c-33.413 0-60.5 9.178-60.5 20.5zM7.812 250c0 22.644 27.087 41 60.5 41 33.414 0 60.5-18.356 60.5-41s-27.086-41-60.5-41c-33.413 0-60.5 18.356-60.5 41z"
              stroke="#0B0B0B"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M7.812 324.5c0 41.697 27.087 75.5 60.5 75.5 33.414 0 60.5-33.803 60.5-75.5s-27.086-75.5-60.5-75.5c-33.413 0-60.5 33.802-60.5 75.5z"
              stroke="#0B0B0B"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <defs>
              <clipPath id="kdalb2tmba">
                <path
                  fill="#fff"
                  transform="rotate(90 67.406 67.406)"
                  d="M0 0h134v134H0z"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </StyledContact>
  )
}
