import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    font-family: "Signika Negative", sans-serif, Arial;
    // cursor: none;
  }
  #root {
    overflow: hidden;
  }
  .panel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
  }
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: all .5s linear;
  }
  .contactMe {
    p {
      color: ${({ theme }) => theme.text};
    }
    .contact_me {
      border-color: ${({ theme }) => theme.secondary} !important;
      a {
        p {
          color: ${({ theme }) => theme.secondary};
        }
        &:hover {
          background-color: ${({ theme }) => theme.secondary};

          p {
            color: ${({ theme }) => theme.background};
          }
        }
      }
    }
  }
  .content__text-inner--stroke {
    -webkit-text-stroke: 2px ${({ theme }) => theme.text};
    text-stroke: 2px ${({ theme }) => theme.text};
  }
  .toggleTheme {
    p {
      color: ${({ theme }) => theme.text};
    }
    .button {
      border: 1px solid ${({ theme }) => theme.secondary};

      .puce {
        background-color: ${({ theme }) => theme.secondary};
      }
    }
  }
  .NavBar {
    @media (max-width: 1024px) {
      &::before {
        background-color: ${({ theme }) => theme.background};
        box-shadow: 0px 12px 20px 0px ${({ theme }) => theme.background};
      }
    }
    .logo_nav {
      .circle_one,
      .circle_two {
        border-color: ${({ theme }) => theme.text};
      }
      p {
        color: ${({ theme }) => theme.text};
      }
      svg {
        path {
          stroke: ${({ theme }) => theme.text};
        }
      }
    }
    .links {
      a,
      p {
        color: ${({ theme }) => theme.text};

        &:hover {color: ${({ theme }) => theme.secondary}};
      }
    }
    .mobile {
      path, 
      line {
        stroke: ${({ theme }) => theme.text};
      }
      .menu-content {
        background-color: ${({ theme }) => theme.background};
        .menu-list {
          a {
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
    
  }
  .ProjectNavbar {
    @media (max-width: 1024px) {
      &::before {
        background-color: ${({ theme }) => theme.background};
        box-shadow: 0px 12px 20px 0px ${({ theme }) => theme.background};
      }
    }
    .logo_nav {
      .circle_one,
      .circle_two {
        border-color: ${({ theme }) => theme.text};
      }
      p {
        color: ${({ theme }) => theme.text};
      }
      svg {
        path {
          stroke: ${({ theme }) => theme.text};
        }
      }
    }
    .link {
      p {
        color: ${({ theme }) => theme.text};
      }
    }
  }
  .orbitAnimation {
    #lottie {
      svg > g > g > g:first-child,
      svg > g > g > g:last-child {
        path {
          stroke: ${({ theme }) => theme.text};
        }
      }
    }
  }
  .Intro {
    .text {
      .links {
        background-color: ${({ theme }) => theme.background_opposite};
        border-color: ${({ theme }) => theme.text_opposite} !important;

        p {
          color: ${({ theme }) => theme.text_opposite};
        }

        &:hover {
          background-color: ${({ theme }) => theme.background};
          border-color: ${({ theme }) => theme.text} !important;

          p {
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
  .DontHover {
    h1 {
      color: ${({ theme }) => theme.text};
    }
  }
  .Weee {
    .content {
      .bg-grid {
        path, rect {
          stroke: ${({ theme }) => theme.text};
        }
      }
      .text {
        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }
    .line-bot {
      background-color: ${({ theme }) => theme.text};
    }
  }
  .Experience {
    border-color: ${({ theme }) => theme.text};
    .title {
      h1 {
        color: ${({ theme }) => theme.text};
      }
    }
    .items {
      .line-begin {
        background-color: ${({ theme }) => theme.text};
      }
      .item-desk,
      .item-mob {
        .features {
          .circle {
            background-color: ${({ theme }) => theme.text};
          }
          .line {
            background-color: ${({ theme }) => theme.text};
          }
        }
        .text {
          h2 {
            color: ${({ theme }) => theme.text};
          }
          p {
            color: ${({ theme }) => theme.text};
          }
        }
      }
    }
  }
  .Contact {
    .content {
      .left {
        .title {
          .first {
            h1 {
              color: ${({ theme }) => theme.text};
            }
          }
        }
      }
      .image-vert {
        .path-smiley {
          stroke: #0B0B0B;
        }
        path {
          stroke: ${({ theme }) => theme.text};
        }
      }
    }
  }
  .CustomCursor {
      circle {
        fill: ${({ theme }) => theme.text};
      }
      .ball {
        border-color: ${({ theme }) => theme.text};
      }
  }
  .Project {
    .title {
      h2 {
        color: ${({ theme }) => theme.text};
      }
    }
    .vue {
      .techno {
        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }
  }
  .Description-project {
    margin-top: 100px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
      }

    .titles {
      margin-right: 80px;

      @media (max-width: 1024px) {
          max-width: 80%;
          margin: 0 auto;
          margin-top: 50px;
        }

      tr {
        height: 20px;
        td {
          font-family: 'Monument', sans-serif;
          font-size: 16px;
          padding-bottom: 8px;
          color: ${({ theme }) => theme.text};
          opacity: 0.6;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          &:last-child {
            padding-left: 20px;

            @media (max-width: 1024px) {
              padding-left: 80px;
            }
            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }

          a {
            background-image: linear-gradient(98deg, #7673eb 9%, #77c0de 60%, #78fad3 93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .description {
      max-width: 450px;

      @media (max-width: 1024px) {
          max-width: 80%;
        }

      p {
        font-family: 'Avenir', sans-serif;
        font-size: 15px;
        line-height: 140%;
        color: ${({ theme }) => theme.text};

        @media (max-width: 1024px) {
          max-width: 80%;
          text-align: center;
          margin: 0 auto;
        }
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
  .Content-project {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-family: 'Monument', sans-serif;
      font-size: 35px;
      margin-top: 150px;
      margin-bottom: 50px;
      text-align: center;
      line-height: 130%;

      @media (max-width: 768px) {
        font-size: 25px;
        max-width: 90%;
      }
    }
    p {
      font-family: 'Avenir', sans-serif;
      font-size: 15px;
      margin-bottom: 50px;
      line-height: 120%;
      width: 100%;
      max-width: 450px;
      text-align: center;

      @media (max-width: 768px) {
        max-width: 85%;
      }
    }
    .image {
      width: 60%;

      @media (max-width: 768px) {
        width: 85%;
      }
    }
    .fullImage {
      width: 100%;
    }
    .imageMed {
      width: 70%;
    }
    .imageBig {
      width: 80%;

      @media (max-width: 768px) {
        width: 90%;
      }
    }
    .mb {
      margin-bottom: 20px;
    }
    .mb-med {
      margin-bottom: 50px;
    }
    .mb-big {
      margin-bottom: 80px;

      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
    .mt-big {
      margin-top: 150px;

      @media (max-width: 768px) {
        margin-top: 100px;
      }
    }
    .website-link {
      color: ${({ theme }) => theme.text};
      border-color: ${({ theme }) => theme.text};

      &:hover {
        color: ${({ theme }) => theme.text_opposite};
        border-color: ${({ theme }) => theme.text_opposite};
        background-color: ${({ theme }) => theme.text};
      }
    }
  }
  .Curri {
    .container-curri {
      border-color: ${({ theme }) => theme.text};
      h2,
      h3,
      p {
        color: ${({ theme }) => theme.text};
      }
      .line {
        background-color: ${({ theme }) => theme.text};
      }
      .kiteHover {
        &:hover {
          color: ${({ theme }) => theme.secondary};
        }
      }
    }
  }
  video {
    width: 100%;
    height: auto;
  }
`
export const lightTheme = {
  background: '#f4f2f0',
  text: '#1e1e1e',
  secondary: '#5ab3ff',
  background_opposite: '#1e1e1e',
  text_opposite: '#ede9e5',
  secondary_opposite: '#78fad3',
}

export const darkTheme = {
  background: '#1e1e1e',
  text: '#ede9e5',
  secondary: '#78fad3',
  background_opposite: '#ebebeb',
  text_opposite: '#1e1e1e',
  secondary_opposite: '#57ca69',
}
