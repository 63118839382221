import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ThemeProvider } from 'styled-components'
import { Intro } from '../Intro/Intro'
import { DontHover } from '../DontHover/DontHover'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import Work from '../Work/Work'
import { Navbar } from '../../Components/Navbar/Navbar'
import { StyledHome } from './Home.styled'
import { Iam } from '../Iam/Iam'
import { Weee } from '../Weee/Weee'
import { Experience } from '../Experience/Experience'
import { Contact } from '../Contact/Contact'
import { ContactMe } from '../../Components/ContactMe/ContactMe'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'
import { Projects } from '../../Components/Projects/Projects'
import { useLocation, useNavigate } from 'react-router-dom'
import { Curri } from '../Curri/Curri'

// import { CustomCursor } from '../../Components/CustomCursor/CustomCursor'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export const Home = () => {
  const windowWidth = useWindowDimensions().width
  const work = useRef(null)
  const panels = useRef([null])
  const panelsContainer = useRef(null)
  const panels2 = useRef([null])
  const panelsContainer2 = useRef(null)
  const panels3 = useRef([null])
  const panelsContainer3 = useRef(null)
  const contact = useRef(null)
  const curri = useRef(null)
  const containerProj = useRef(null)
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const location = useLocation()
  const [from, setFrom] = useState(location.state)
  const navigate = useNavigate()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel
  }
  const createPanelsRefs2 = (panel, index) => {
    panels2.current[index] = panel
  }
  const createPanelsRefs3 = (panel, index) => {
    panels3.current[index] = panel
  }

  useEffect(() => {
    const totalPanels = panels.current.length
    const totalPanels2 = panels2.current.length
    const totalPanels3 = panels3.current.length

    let maxWidth = 0

    const getMaxWidth = () => {
      maxWidth = 0
      panels.current.forEach((section) => {
        maxWidth += section.offsetWidth
      })
    }
    getMaxWidth()

    ScrollTrigger.addEventListener('refreshInit', getMaxWidth)

    ScrollTrigger.matchMedia({
      '(min-width: 1025px)': function () {
        gsap.to(panels.current, {
          xPercent: -100 * (totalPanels - 1),
          ease: 'none',
          scrollTrigger: {
            trigger: panelsContainer.current,
            pin: true,
            scrub: 1,
            end: () => '+=' + panelsContainer.current.offsetWidth,
          },
        })
        gsap.to(panels2.current, {
          xPercent: -100 * (totalPanels2 - 1),
          ease: 'none',
          scrollTrigger: {
            trigger: panelsContainer2.current,
            pin: true,
            scrub: 1,
            end: () => '+=' + panelsContainer2.current.offsetWidth,
          },
        })
        gsap.to(panels3.current, {
          xPercent: -100 * (totalPanels3 - 1),
          ease: 'none',
          scrollTrigger: {
            trigger: panelsContainer3.current,
            pin: true,
            scrub: 1,
            end: () => '+=' + panelsContainer3.current.offsetWidth,
          },
        })

        panels.current.forEach((sct) => {
          ScrollTrigger.create({
            trigger: sct,
            start: () =>
              'top top-=' +
              (sct.offsetLeft - window.innerWidth / 4) *
                (maxWidth / (maxWidth - window.innerWidth)),
            end: () =>
              '+=' +
              sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
            onEnter: () => {
              work.current.activeAnim(false)
            },
            onLeaveBack: () => {
              if (work.current !== null) {
                work.current.activeAnim(true)
              }
            },
          })
        })
        if (from) {
          if (from.fromOtherPage) {
            work.current.activeAnim(false)
            gsap.to(window, {
              scrollTo: {
                y:
                  containerProj.current.offsetTop +
                  containerProj.current.offsetLeft,
                autoKill: false,
              },
              duration: 2,
            })
            navigate({ state: { fromOtherPage: false } })
            //navigate.replace({ state: { fromOtherPage: false } })
            setFrom(false)
          } else {
            work.current.activeAnim(true)
          }
        } else {
          work.current.activeAnim(true)
        }
        if (from) {
          if (from.fromOtherPage) {
            work.current.activeAnim(false)
            gsap.to(window, {
              scrollTo: {
                y:
                  containerProj.current.offsetTop +
                  containerProj.current.offsetLeft,
                autoKill: false,
              },
              duration: 2,
            })
            setFrom(false)
          } else {
            work.current.activeAnim(true)
          }
        } else {
          work.current.activeAnim(true)
        }
      },

      '(max-width: 1024px)': function () {
        gsap.killTweensOf(panels.current)
        gsap.killTweensOf(panels2.current)
        gsap.killTweensOf(panels3.current)
        ScrollTrigger.removeEventListener('refreshInit', getMaxWidth)
        ScrollTrigger.getAll().forEach((t) => t.kill())
      },
    })

    return () => {
      gsap.killTweensOf(panels.current)
      gsap.killTweensOf(panels2.current)
      gsap.killTweensOf(panels3.current)
      ScrollTrigger.removeEventListener('refreshInit', getMaxWidth)
      ScrollTrigger.getAll().forEach((t) => t.kill())
    }
  }, [
    panels,
    panels2,
    panels3,
    panelsContainer,
    panelsContainer2,
    panelsContainer3,
    work,
  ])
  useEffect(() => {
    ScrollTrigger.getAll().forEach((t) => t.refresh())
  })
  const nav = (containerOffset) => {
    gsap.to(window, {
      scrollTo: {
        y: containerOffset,
        autoKill: false,
      },
      duration: 2,
    })
  }
  const handleNavigation = (link) => {
    if (
      link.current.innerText == 'Contact' ||
      link.current.innerText == 'CONTACT ME'
    ) {
      nav(contact.current.offsetTop + contact.current.offsetLeft)
    }
    if (link.current.innerText == 'Home') {
      nav(panelsContainer.current.offsetTop + panels.current[0].offsetLeft)
    }
    if (link.current.innerText == 'About') {
      nav(
        panelsContainer2.current.parentElement.offsetTop +
          panels2.current[1].offsetLeft +
          windowWidth
      )
    }
    if (link.current.innerText == 'Work') {
      nav(
        containerProj.current.parentElement.offsetTop +
          panels.current[1].offsetLeft +
          windowWidth
      )
    }
    if (link.current.innerText == 'Profile') {
      nav(
        panelsContainer3.current.parentElement.offsetTop +
          panels3.current[1].offsetLeft +
          windowWidth
      )
    }
    if (link.current.innerText == 'MY SKILLS') {
      nav(curri.current.offsetTop + curri.current.offsetLeft)
    }
  }
  const handleNavigationMob = (link) => {
    if (link == 'Home') {
      nav(0)
    }
    if (link == 'About') {
      nav(document.getElementById('container2').offsetTop)
    }
    if (link == 'Work') {
      nav(document.getElementById('container-projects').offsetTop)
    }
    if (link == 'Profile') {
      nav(document.getElementById('exp').offsetTop)
    }
    if (link == 'Contact') {
      nav(contact.current.offsetTop)
    }
  }

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      {/*<CustomCursor />*/}
      <ToggleDarkMode sendToHome={sendToHome} />
      <ContactMe />
      <StyledHome>
        <Navbar
          handleNav={handleNavigation}
          handleNavigationMob={handleNavigationMob}
        />
        <div className="container" id="container1" ref={panelsContainer}>
          <section className="panel" ref={(e) => createPanelsRefs(e, 0)}>
            <Intro handleNav={handleNavigation} />
          </section>
          <section className="panel" ref={(e) => createPanelsRefs(e, 1)}>
            <Work ref={work} id="work" />
          </section>
        </div>
        <div
          className="vertical selected-work"
          id="container-projects"
          ref={containerProj}
        >
          <div className="abs-blur">
            <div className="blur"></div>
          </div>
          <Projects />
        </div>
        <div className="container2" id="container2" ref={panelsContainer2}>
          {/*<section className="panel" ref={(e) => createPanelsRefs2(e, 0)}>
            <h1>Projects</h1>
  </section>*/}
          <section className="panel" ref={(e) => createPanelsRefs2(e, 0)}>
            <DontHover />
          </section>
          <section
            className="panel"
            id="iam"
            ref={(e) => createPanelsRefs2(e, 1)}
          >
            <Iam />
          </section>
        </div>
        <div className="container3" id="container3" ref={panelsContainer3}>
          <section className="panel" ref={(e) => createPanelsRefs3(e, 0)}>
            <Weee />
          </section>
          <section
            className="panel"
            id="exp"
            ref={(e) => createPanelsRefs3(e, 1)}
          >
            <Experience />
          </section>
        </div>
        <div className="vertical curri" ref={curri}>
          <Curri />
        </div>
        <div className="vertical last-page" ref={contact}>
          <Contact />
        </div>
      </StyledHome>
    </ThemeProvider>
  )
}
