import styled from 'styled-components'

export const StyledEmailingManager = styled.div`
  width: 100%;
  padding-bottom: 150px;

  img {
    width: 100%;
    height: auto;
  }
`
