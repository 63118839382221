import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledBeclou } from './Beclou.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import Img1 from '../../Assets/Img/Beclou/img-1.webp'
import Img2 from '../../Assets/Img/Beclou/img-2.webp'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Beclou = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledBeclou>
        <ProjectNavbar />
        <IntroProject title={'Beclou app'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2020
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    School project
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno front
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    NextJS
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    Techno back
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    NodeJS
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              School project to create and developing a bike rental website and
              app in less than a week.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <div className="imageBig mb-big mt-big">
            <img src={Img1} alt="bonhomme" />
          </div>
          <div className="imageBig">
            <img src={Img2} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledBeclou>
    </ThemeProvider>
  )
}
