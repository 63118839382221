import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'
import { StyledBlur } from './Blur.styled'

export const Blur = (props) => {
  const blur = useRef(null)
  const windowWidth = useWindowDimensions().width
  var tl = gsap.timeline()
  useEffect(() => {
    if (windowWidth > 1024) {
      tl.to(blur.current, {
        x: 'random(-150, 600)',
        y: 'random(-200, 200)',
        duration: 5,
        ease: 'Power0.easeOut',
        repeat: -1,
        repeatRefresh: true,
      })
    }
  })

  return <StyledBlur position={props.position} ref={blur} />
}
