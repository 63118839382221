import styled from 'styled-components'

export const StyledAganippe = styled.div`
  width: 100%;
  padding-bottom: 150px;

  .mt-aganippe {
    margin-top: 100px;
  }

  .multi {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .big {
      width: 72%;
    }
    .small {
      width: 20%;
    }
  }

  img,
  video {
    width: 100%;
    height: auto;
  }
`
