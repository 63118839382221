import React from 'react'
import { StyledHand } from './Hand.styled'
import main from '../../Assets/Img/main.svg'

export const Hand = () => {
  return (
    <StyledHand>
      <p>Scroll to start</p>
      <div className="imageHand">
        <img src={main} alt="main" />
      </div>
    </StyledHand>
  )
}
