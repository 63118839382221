import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { StyledProjectNavbar } from './ProjectNavbar.styled'
import { animationLogo } from './json'
import Lottie from 'lottie-web'
import main from '../../Assets/Img/main-back.svg'

export const ProjectNavbar = () => {
  const logo = useRef()
  const [anim, setAnim] = useState()

  useEffect(() => {
    var params = {
      container: logo.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: animationLogo,
    }
    setAnim(Lottie.loadAnimation(params))
  }, [])
  const activeAnim = () => {
    anim.play()
  }
  const unactiveAnim = () => {
    anim.stop()
  }
  return (
    <StyledProjectNavbar className="ProjectNavbar">
      <div
        className="logo_nav"
        onMouseEnter={activeAnim}
        onMouseLeave={unactiveAnim}
      >
        <p>
          jérémy
          <br />
          lichtmann
        </p>
        {/*<div id="logo" ref={logo}></div>*/}
        <Link
          to={'/'}
          state={{ fromOtherPage: true }}
          id="logo"
          ref={logo}
        ></Link>
      </div>
      <div className="link">
        <Link to={'/'} state={{ fromOtherPage: true }} className="goBack">
          <div className="image">
            <img src={main} alt="bonhomme" />
          </div>
          <p>Go Back</p>
        </Link>
      </div>
    </StyledProjectNavbar>
  )
}
