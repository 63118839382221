import styled from 'styled-components'

export const StyledWeee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .images {
      width: 45%;

      @media (max-width: 1024px) {
        background-image: url(${(props) => props.grille});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 500px;
        height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 768px) {
        width: 400px;
      }
      @media (max-width: 450px) {
        width: 100%;
        height: 350px;
      }

      .picture {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img {
          position: relative;
          z-index: 3;
          width: 400px;
          height: 400px;
          transition: all 0.5s ease-in-out;

          @media (max-width: 1350px) {
            width: 350px;
            height: 350px;
          }

          @media (max-width: 1024px) {
            width: 350px;
            height: 350px;
          }
          @media (max-width: 768px) {
            width: 80%;
            height: auto;
          }
        }
        .calc-img {
          position: absolute;
          z-index: 4;
          top: 0;
          left: 0;
          width: 400px;
          height: 400px;

          @media (max-width: 1350px) {
            width: 350px;
            height: 350px;
          }

          @media (max-width: 1024px) {
            width: 350px;
            height: 350px;
          }
          @media (max-width: 768px) {
            width: 90%;
            height: auto;
          }
        }
        .bg-grid {
          position: absolute;
          z-index: 2;
          top: -50px;
          right: -120px;
          width: 478px;
          height: 525px;
          /*background-image: url(${(props) => props.grille});
          background-repeat: no-repeat;
          background-size: contain;*/

          @media (max-width: 1350px) {
            width: 400px;
            height: 475px;
            right: -50px;
          }
          @media (max-width: 1024px) {
            display: none;
          }

          img,
          svg {
            height: 100%;
            width: auto;

            @media (max-width: 1024px) {
              width: 100%;
              height: auto;
            }
          }
        }

        .main {
          position: absolute;
          top: 50px;
          right: -200px;
          width: 180px;

          @media (max-width: 1350px) {
            width: 150px;
            right: -100px;
          }
          @media (max-width: 1024px) {
            width: 150px;
            right: -90px;
          }
          @media (max-width: 768px) {
            width: 100px;
            right: -65px;
          }
          @media (max-width: 450px) {
            width: 80px;
            right: -45px;
            top: 30px;
          }
        }

        &:hover {
          .img {
            transform: rotate(-15deg);
          }
          .main {
            animation: checkImage 1s infinite ease-in-out;
          }
        }
      }
      @keyframes checkImage {
        0% {
          transform: rotate(0);
        }
        20% {
          transform: rotate(10deg);
        }
        40% {
          transform: rotate(-15deg);
        }
        60% {
          transform: rotate(5deg);
        }
        80% {
          transform: rotate(-5deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }
    .text {
      position: relative;
      width: 45%;

      @media (max-width: 1024px) {
        width: 370px;
        margin-top: 50px;
      }
      @media (max-width: 450px) {
        width: 100%;
        margin-top: 20px;
      }

      p {
        font-family: 'Avenir', sans-serif;
        font-size: 13px;
        line-height: 140%;
        margin-bottom: 20px;
        max-width: 400px;

        b {
          font-family: 'AvenirHeavy', sans-serif;
          font-weight: 800;
        }

        @media (max-width: 1350px) {
          max-width: 400px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .weeeeee {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      height: 100%;
      bottom: 0;
      left: 55%;

      @media (max-width: 1024px) {
        display: none;
      }

      .gradient {
        position: relative;
        width: fit-content;
        overflow: hidden;
        cursor: pointer;

        h1 {
          font-family: 'Monument', sans-serif;
          font-size: 35px;
          font-style: normal;
          font-weight: normal;
          text-align: center;
          width: fit-content;
          background-image: linear-gradient(
            98deg,
            #7673eb 9%,
            #77c0de 60%,
            #78fad3 93%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 0;
        }
        .line {
          position: absolute;
          bottom: -2px;
          width: 100%;
          height: 6px;
          background-image: linear-gradient(
            98deg,
            #7673eb 9%,
            #77c0de 60%,
            #78fad3 93%
          );
          transition: all 0.3s ease-in-out;
          transform: translateX(-105%);
        }
        &:hover {
          .line {
            transform: translateX(0);
          }
        }
      }

      .baloon {
        opacity: 0;
        position: absolute;
        bottom: -235px;
        left: calc(50% - 50px);
        width: 100px;
        pointer-events: none;
      }
    }
  }
  .line-bot {
    z-index: 5;
    height: 2px;
    position: absolute;
    right: 0;
    left: calc(63% - 480px);
    bottom: calc((100% - 548px) / 2);

    @media (max-width: 1350px) {
      bottom: calc((100% - 498px) / 2);
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`
