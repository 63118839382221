import React, { useRef, useEffect, useState } from 'react'
import { StyledWeee } from './Weee.styled'
import { gsap } from 'gsap'
import main from '../../Assets/Img/main_signe.svg'
import baloonImg from '../../Assets/Img/baloon.svg'
import { Blur } from '../../Components/Blur/Blur'
import profil from '../../Assets/Img/profil.webp'
import grille from '../../Assets/Img/grille.svg'

export const Weee = () => {
  const [distance, setDistance] = useState(0)
  const baloon = useRef()
  const cta = useRef()
  const weeee = useRef()

  let throttleTimer
  const throttle = (callback, time = 3000) => {
    if (throttleTimer) return
    throttleTimer = true
    callback()
    setTimeout(() => {
      throttleTimer = false
    }, time)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let heightDiv = weeee.current.getBoundingClientRect().height
      let screenHeight = window.innerHeight
      let dist = screenHeight - (screenHeight / 2 - heightDiv / 2)
      setDistance(dist)
    }
    return () => {
      isMounted = false
    }
  }, [cta])
  var tl = gsap.timeline({ paused: true })
  useEffect(() => {
    tl.from(baloon.current, { opacity: 0, y: 0, duration: 0.001 })
      .to(baloon.current, { opacity: 1, duration: 0.5 }, '<')
      .to(
        baloon.current,
        { duration: 3, y: -distance, ease: 'Bounce.easeOut' },
        '<'
      )
      .to(baloon.current, { opacity: 0, duration: 0.1, delay: 2.9 }, '<')
  })
  const anim = () => {
    tl.restart()
  }
  const throttledAim = () => {
    throttle(anim)
  }
  return (
    <StyledWeee className="Weee" grille={grille}>
      <Blur position={'center'} />
      <div className="content">
        <div className="images">
          <div className="picture">
            <div className="img">
              <img src={profil} alt="bonhomme" />
            </div>
            <div className="calc-img">
              <div className="main">
                <img src={main} alt="bonhomme" />
              </div>
            </div>
            <div className="bg-grid">
              <svg
                width="480"
                height="579"
                viewBox="0 0 480 579"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 65.5794C44.1771 65.5794 87.8713 67.9006 130.999 65.3776C167.738 63.2346 203.042 48.6191 240 48.6191C276.959 48.6191 312.262 63.2346 349.001 65.3776C392.129 67.9006 435.823 65.5794 479 65.5794"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 129.411H69.3567C89.5888 129.411 111.113 131.916 131.132 128.286C168.797 121.449 201.223 87.5811 239.998 87.5811C278.772 87.5811 311.203 121.449 348.863 128.286C367.897 131.737 388.338 129.411 407.566 129.411H479"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 193.388C21.7665 193.632 42.5331 193.906 63.2997 194.21C82.1904 194.474 101.678 196.388 120.494 194.012C160.925 188.895 199.069 163.715 240.003 163.649C280.349 163.583 317.783 188.685 357.622 193.646C375.787 195.914 394.588 194.18 412.838 194C434.89 193.784 456.944 193.584 479 193.4"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 257.024C33.1868 257.145 65.3836 257.667 97.5704 257.65C145.083 257.617 192.466 252.703 239.993 252.703C285.646 252.676 331.15 257.568 376.788 257.562C410.854 257.562 444.929 257.139 479 257.046"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 321.262C33.1271 321.145 65.2592 320.7 97.3864 320.732C144.948 320.732 192.426 324.441 239.993 324.441C285.676 324.441 331.269 320.796 376.947 320.817C410.963 320.817 444.984 321.182 479 321.273"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 384.968C40.3159 384.511 81.0336 380.33 120.21 384.209C160.511 388.194 199.384 407.859 240.012 407.93C280.004 407.989 318.187 388.401 357.871 384.553C397.729 380.686 439.023 384.636 479 384.98"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 448.812H69.3567C89.6932 448.812 110.835 446.793 131.042 449.696C168.29 455.037 202.049 482.143 240.007 482.143C277.966 482.143 311.72 455.031 348.973 449.696C368.176 446.938 388.244 448.812 407.586 448.812H479"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1 512.619C44.2467 512.619 87.7568 510.759 130.954 512.781C167.564 514.511 203.255 526.67 240 526.67C276.745 526.67 312.461 514.524 349.046 512.781C392.243 510.734 435.778 512.619 479 512.619"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M425.743 1C425.743 53.1554 423.981 105.777 425.884 157.885C427.501 202.156 439 245.026 439 289.5C439 333.974 427.485 376.844 425.879 421.115C423.996 473.229 425.743 525.839 425.743 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M372.613 1V87.2224C372.613 110.495 370.862 134.928 373.429 158.016C378.433 203.239 404.02 243.209 404 289.503C403.98 335.797 378.413 375.749 373.444 420.984C370.762 445.286 372.638 470.994 372.638 495.487V578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M320.06 1C319.711 48.7303 315.937 98.5188 319.562 146.052C323.262 194.512 341.97 240.579 342 289.5C342.045 337.553 323.431 382.693 319.885 430.268C316.265 478.728 319.816 529.33 320.05 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M266.503 1C266.402 40.7434 266 80.4927 266 120.242C266 176.689 269.97 233.023 270 289.488C270.025 343.279 266.055 396.92 266.111 450.693C266.161 493.123 266.443 535.564 266.513 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M213.426 1C213.532 40.7494 213.989 80.5107 214 120.26C214 176.719 209.032 233.011 209 289.488C208.968 343.291 213.952 396.89 213.883 450.669C213.835 493.111 213.501 535.558 213.426 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M160.609 1C160.8 27.3241 161.013 53.6681 161.247 80.0321C161.433 101.857 162.991 124.412 160.991 146.123C156.513 194.775 134.045 240.172 134 289.5C133.955 338.008 156.353 382.382 160.669 430.196C162.755 453.343 161.136 477.394 161.011 500.661C160.87 526.439 160.735 552.218 160.604 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M107.252 1V87.2224C107.252 110.387 109.308 135.21 106.263 158.111C100.213 203.783 69.9753 242.306 70 289.503C70.0247 336.7 100.272 375.199 106.288 420.889C109.461 444.993 107.252 471.143 107.252 495.487V578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M54.1356 1C54.1356 53.1032 56.1938 105.912 53.9584 157.938C52.0529 202.359 38.9951 244.776 39 289.503C39.0049 334.23 52.0774 376.641 53.9632 421.062C56.174 473.112 54.1356 525.897 54.1356 578"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <rect
                  x="2"
                  y="2"
                  width="476"
                  height="575"
                  stroke="#EDE9E5"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text">
          <p>
            After a CPGE in physics &amp; engineer science, I studied{' '}
            <b>3 years in an engineering school</b> in electronics and embedded
            systems. Quickly <b>attracted by the code</b> and <b>self-taught</b>
            , I decided to complete my studies with a{' '}
            <b>Master in web development</b>.
          </p>
          <p>
            I have always loved <b>creating new things</b>, and I am always
            looking for the <b>best methods</b> and <b>technologies</b> to carry
            out my projects. I pay particular attention to performance and
            interactions for a pleasant user experience.
          </p>
          <p>
            I am attentive to the needs and I do not hesitate to give my point
            of view.
          </p>
        </div>
        <div className="weeeeee" ref={weeee}>
          <div className="gradient">
            <h1 ref={cta} onMouseEnter={throttledAim}>
              Weeee!
            </h1>
            <div className="line"></div>
          </div>
          <div className="baloon" ref={baloon}>
            <img src={baloonImg} alt="bonhomme" />
          </div>
        </div>
      </div>
      <div className="line-bot"></div>
    </StyledWeee>
  )
}
