import React, { useState } from 'react'
import { StyledCurri } from './Curri.styled'
// import kite from '../../Assets/Img/kite.gif'
export const Curri = () => {
  const [displayKite, setDisplayKite] = useState(false)
  return (
    <StyledCurri className="Curri" displayKite={displayKite}>
      <div className="container-curri">
        <div className="skills">
          <h2>Skills</h2>
          <p>
            Backend and frontend development of websites
            <br />
            Deployment orchestration via continuous deployment tools
            Adaptability, team spirit
          </p>
          <p>
            Languages &amp; framework : ReactJS, NextJS, NodeJS, ExpressJS,
            Javascript, jQuery, PHP, MongoDB, MySQL, HTML, CSS
          </p>
          <p>Tools : Docker, Jenkins, Git</p>
          <p>Environments : Windows, macOS, Linux</p>
          <p>
            Méthode agile, Scrum, DevOps, Opquast (815/1000), Zeplin, Suite
            Adobe
          </p>
        </div>
      </div>
      <div className="container-curri">
        <div className="lang-interest">
          <div className="lang">
            <h2>Languages</h2>
            <h3>french (native)</h3>
            <h3>english (B1 - B2)</h3>
            <h3>spanish (notions)</h3>
          </div>
          <div className="line"></div>
          <div className="interest">
            <h2>Interest</h2>
            <p
              className="kiteHover"
              onMouseEnter={() => setDisplayKite(true)}
              onMouseLeave={() => setDisplayKite(false)}
            >
              Kitesurf
            </p>
            <p>Sailing</p>
            <p>FPV Drones</p>
            <p>MAO</p>
            <p>Guitar</p>
            <div className="kite">
              <iframe
                src="https://giphy.com/embed/l3q2LHccmWhU6olZ6"
                width="480"
                height="270"
                title="Gif kitesurf"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
              {/*<img src={kite} alt="bonhomme" />*/}
            </div>
          </div>
        </div>
      </div>
    </StyledCurri>
  )
}
