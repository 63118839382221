import styled from 'styled-components'

export const StyledProjectNavbar = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 0 10px;

  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }

  .logo_nav {
    position: relative;
    width: 175px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1024px) {
      width: 120px;
    }

    p {
      position: absolute;
      font-family: 'Monument', sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 8px;
      }
    }
    svg {
      width: 100%;
    }

    .circle_one {
      position: absolute;
      left: 20px;
      width: calc(100% - 50px);
      border: 1px solid;
      height: 100%;
      border-radius: 50% 50%;
    }
    .circle_two {
      position: absolute;
      right: 20px;
      width: calc(100% - 50px);
      border: 1px solid;
      height: 100%;
      border-radius: 50% 50%;
    }
  }

  .link {
    position: relative;

    .goBack {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;

      .image {
        width: 50px;
        margin-right: 20px;

        @media (max-width: 768px) {
          width: 40px;
          margin-right: 15px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      &:hover {
        .image {
          animation: animHandNav 1s infinite ease-in-out alternate;
        }
      }
      @keyframes animHandNav {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(15px);
        }
      }

      p {
        font-family: 'Avenir', sans-serif;
        font-size: 20px;

        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }
`
