import styled from 'styled-components'

export const StyledThetiptop = styled.div`
  width: 100%;
  padding-bottom: 150px;

  .multi {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 48%;

      @media (max-width: 768px) {
        width: 100%;
      }

      .item {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;

          @media (max-width: 768px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }
`
