import React from 'react'
import { Project } from '../Project/Project'
import { StyledProjects } from './Projects.styled'

import aganippe from '../../Assets/Img/Projects/aganippe.webp'
import alice from '../../Assets/Img/Projects/alicePortfolio.webp'
import thetiptop from '../../Assets/Img/Projects/thetiptop.webp'
import emailingmanager from '../../Assets/Img/Projects/emailingmanager.webp'
import beclou from '../../Assets/Img/Projects/beclou.webp'
import fahrenheit from '../../Assets/Img/Projects/360.webp'
import yama from '../../Assets/Img/Projects/yama.webp'

export const Projects = () => {
  return (
    <StyledProjects>
      <Project
        title={'Aganippe'}
        image={aganippe}
        technos={'NextJS, Strapi, MySQL'}
        link={'Aganippe'}
      />
      <Project
        title={"Alice's Portfolio"}
        image={alice}
        technos={'ReactJS, NodeJS'}
        link={'AlicesPortfolio'}
      />
      <Project
        title={'ThéTipTop'}
        image={thetiptop}
        technos={'AngularJS, NodeJS'}
        link={'TheTipTop'}
      />
      <Project
        title={'Emailing Manager'}
        image={emailingmanager}
        technos={'ReactJS, NodeJS'}
        link={'EmailingManager'}
      />
      <Project
        title={'Beclou'}
        image={beclou}
        technos={'ReactJS, NodeJS'}
        link={'Beclou'}
      />
      <Project
        title={'360º Website'}
        image={fahrenheit}
        technos={'PHP, Javascript'}
        link={'360Fahrenheit'}
      />
      <Project
        title={'Real Estates'}
        image={yama}
        technos={'PHP, Javascript'}
        link={'RealEstates'}
      />
    </StyledProjects>
  )
}
