import styled from 'styled-components'

export const StyledBlur = styled.div`
  position: absolute;
  z-index: 1;
  ${(props) =>
    props.position === 'left'
      ? 'left: -300px'
      : props.position === 'right'
      ? 'right: 0'
      : 'left: calc(50% - 250px)'};
  top: ${(props) => (props.position === 'center' ? '0' : '-20vh')};
  width: 1000px;
  height: 140vh;
  background-image: url('/blur.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  /*-webkit-filter: blur(400px);
  filter: blur(400px);
  background-image: linear-gradient(
    98deg,
    #7673eb 9%,
    #77c0de 60%,
    #78fad3 93%
  );*/
`
