import React, { useEffect, useRef } from 'react'
import { animationData } from './json'
import { StyledOrbitAnimation } from './OrbitAnimation.styled'
import Lottie from 'lottie-web'

export const OrbitAnimation = () => {
  const lottie = useRef()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      var params = {
        container: lottie.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      }
      var anim = Lottie.loadAnimation(params)
      anim.play()
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <StyledOrbitAnimation className="orbitAnimation">
      <div id="lottie" ref={lottie}></div>
    </StyledOrbitAnimation>
  )
}
