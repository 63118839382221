import styled from 'styled-components'

export const StyledWork = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    margin-top: 150px;
  }

  p {
    font-family: 'Monument', sans-serif;
    font-size: 23px;
    background-image: linear-gradient(
      98deg,
      #7673eb 9%,
      #77c0de 60%,
      #78fad3 93%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
  .image {
    width: 115px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 90px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 70px;

    @media (max-width: 1024px) {
      font-size: 50px;
    }
  }
  .title {
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: inner;
    width: 100%;
    @media (max-width: 1024px) {
      height: 250px;
    }
    @media (max-width: 768px) {
      height: 200px;
    }
    .content__text {
      overflow: hidden;
      flex: 1;
      opacity: 0;
      pointer-events: none;
      height: 28px;
      #full {
        opacity: 0;
      }
      .content__text-inner {
        font-family: 'Monument', sans-serif;
        display: block;
        text-transform: uppercase;
        font-size: 3.5rem;
        font-weight: normal;
        line-height: 0.9;

        @media (max-width: 1024px) {
          font-size: 45px;
          line-height: inherit;
        }
        @media (max-width: 768px) {
          font-size: 30px;
        }
        @media (max-width: 450px) {
          font-size: 22px;
        }
      }
      .content__text-inner--stroke {
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        color: transparent;
      }
      .content__text-inner--bottom {
        transform: translate3d(0, -40%, 0);
      }
    }
    .content__text--full {
      flex: none;
      height: 50px;
    }
  }
`
