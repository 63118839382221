import styled from 'styled-components'

export const StyledHome = styled.div`
  .container {
    // width: 600%;
    width: 200%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
  }
  .container2 {
    // width: 600%;
    position: relative;
    z-index: 5;
    width: 200%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
  }
  .container3 {
    // width: 600%;
    width: 200%;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
  }
  .selected-work {
    //height: 1500px;
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .abs-blur {
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 1;

      .blur {
        position: sticky;
        top: 0;
        width: 1000px;
        height: 140vh;
        background-image: url('/blur.png');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .curri {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  .last-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  /*.weee {
    height: 100vh;
    width: 100vw;
    background-color: green;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    h1 {
      margin-top: 200px;
      color: #ffffff;
      font-size: 40px;
      text-align: center;
    }
  }*/
`
