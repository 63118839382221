import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledThetiptop } from './Thetiptop.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import Img1 from '../../Assets/Img/TTT/img-1.webp'
import Img2 from '../../Assets/Img/TTT/img-2.webp'
import Img3 from '../../Assets/Img/TTT/img-3.webp'
import Img4 from '../../Assets/Img/TTT/img-4.webp'
import Img5 from '../../Assets/Img/TTT/img-5.webp'
import Img6 from '../../Assets/Img/TTT/img-6.webp'
import Img7 from '../../Assets/Img/TTT/img-7.webp'
import Img8 from '../../Assets/Img/TTT/img-8.webp'
import Img9 from '../../Assets/Img/TTT/img-9.webp'
import Img10 from '../../Assets/Img/TTT/img-10.webp'
import Img11 from '../../Assets/Img/TTT/img-11.webp'
import Img12 from '../../Assets/Img/TTT/img-12.webp'
import Img13 from '../../Assets/Img/TTT/img-13.webp'
import Img14 from '../../Assets/Img/TTT/img-14.webp'
import Img15 from '../../Assets/Img/TTT/img-15.webp'
import Img16 from '../../Assets/Img/TTT/img-16.webp'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Thetiptop = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledThetiptop>
        <ProjectNavbar />
        <IntroProject title={'ThéTipTop'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2021
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    School project
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno front
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    AngularJS
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    Techno back
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    NodeJS
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              School project consisting of the creation of a contest game
              website around tea and the establishment of a server of
              integration and continuous deployment.
              <br />
              All the tools used are on a Debian10 server and are based on
              Docker containers managed with Docker Compose.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <h2>Homepage</h2>
          <div className="image">
            <img src={Img1} alt="bonhomme" />
          </div>
          <h2>Website views</h2>
          <p>
            The website offers the possibility to register and connect to your
            personal space. Three roles are present: administrator, employee and
            customer. Once registered, the customer receives a confirmation
            email to validate his account. When logged in, he has the ability to
            see the different gains he has earned and their status ( withdrawn
            or pending withdrawal ). He can also change his personal
            informations. From the employee account, winnings are assigned to
            clients and withdrawn. The admin account allows to view the stats of
            the competition game, the stores&apos;s stocks, to download the
            emailing list of registered customers, and to modify the accounts
            employees and customers
          </p>
          <div className="multi">
            <div className="items">
              <div className="item">
                <img src={Img2} alt="bonhomme" />
              </div>
              <div className="item">
                <img src={Img3} alt="bonhomme" />
              </div>
              <div className="item">
                <img src={Img4} alt="bonhomme" />
              </div>
            </div>
            <div className="items">
              <div className="item">
                <img src={Img5} alt="bonhomme" />
              </div>
              <div className="item">
                <img src={Img6} alt="bonhomme" />
              </div>
              <div className="item">
                <img src={Img7} alt="bonhomme" />
              </div>
              <div className="item">
                <img src={Img8} alt="bonhomme" />
              </div>
            </div>
          </div>
          <h2>
            Reverse-proxy
            <br />
            &amp; Load balancer
          </h2>
          <p>
            As a reverse-proxy &amp; load balancer, we use Traefik. He is
            perfect for micro services like Docker containers.
            <br />
            To generate SSL certicicate, we use Let&apos;s encrypt.
          </p>
          <div className="image mb">
            <img src={Img9} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img10} alt="bonhomme" />
          </div>
          <h2>Metrics</h2>
          <p>
            In order to get real time information about the websites or the
            continuous deployment server, a metrics system is present. Thanks to
            CadVisor, the metrics exposed by the containers are retrieved and
            stored using Prometheus. In order to get a clear view of these data,
            they are displayed with Grafana in different dashboards. An alert
            system is also present with the sending of Slack messages and emails
            in case of server performance problems or a particular website.
          </p>
          <div className="image mb">
            <img src={Img11} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img12} alt="bonhomme" />
          </div>
          <h2>Backups</h2>
          <p>
            Various backups are present on the server. A regular backup of the
            continuous integraton server is performed on a private ftp. All
            projects on GitLab have a daily backup in an AWS S3 bucket with a
            7-days lifespan. And all containers images are automatically backed
            up to DockerHub during a deployment.
          </p>
          <div className="image mb">
            <img src={Img13} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img14} alt="bonhomme" />
          </div>
          <h2>Message management</h2>
          <p>
            Different types of messages are sent automatically when using the
            workflow, usually from Jenkins or Grafana. They are sent by message
            on Slack and/or by email. They include informations on the status of
            a Jenkins build, for example, reports of tests carried out during
            deployment or alerts related to metrics.
          </p>
          <div className="image mb">
            <img src={Img15} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img16} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledThetiptop>
    </ThemeProvider>
  )
}
