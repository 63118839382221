import styled from 'styled-components'

export const StyledHoverImage = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  @media (max-width: 1024px) {
    display: none;
  }

  .image {
    opacity: ${(props) => (props.active === false ? 0 : 1)};
    position: absolute;
    width: auto;
    height: auto;
    max-height: 50%;
    max-width: 60%;
    transform: translate(
      ${(props) => (props.xPos === '50%' ? '50%' : props.xPos + 'px')},
      ${(props) => (props.yPos === '50%' ? '50%' : props.yPos + 'px')}
    );
    z-index: 99;
    pointer-events: none;
  }
`
