import React, { useState, useRef, useMemo } from 'react'
import { throttle } from 'lodash'
import { StyledIam } from './Iam.styled'
import face from '../../Assets/Img/face.svg'
import { HoverImage } from '../../Components/HoverImage/HoverImage'

import bgPatch from '../../Assets/Img/bg-me.svg'
import centerPatch from '../../Assets/Img/center-patch-me.svg'

export const Iam = () => {
  const [activeImg, setActiveImg] = useState(false)
  const [xPos, setXPos] = useState(0)
  const [yPos, setYPos] = useState(0)
  const showImg = useRef()
  const handleMouse = useMemo(() => {
    const throttled = throttle((e) => {
      setXPos(e.clientX)
      setYPos(e.clientY)
    }, 100)
    return (e) => {
      e.persist()
      return throttled(e)
    }
  }, [])
  return (
    <StyledIam className="DontHover" image={face}>
      <div className="image-iam">
        <div className="center">
          <img src={centerPatch} alt="bonhomme" />
        </div>
        <div className="bg">
          <img src={bgPatch} alt="bonhomme" />
        </div>
      </div>
      <div className="text">
        <div className="first-line">
          <h1 className="desk">I&apos;m&nbsp;</h1>
          <div className="gradient desk">
            <h1
              onMouseMove={handleMouse}
              onMouseEnter={() => setActiveImg(true)}
              onMouseLeave={() => setActiveImg(false)}
              ref={showImg}
            >
              Jérémy
            </h1>
            <div className="line"></div>
          </div>
          <h1 className="desk">, web developer</h1>
        </div>
        <h1 className="desk">based in Paris, currently looking for a job.</h1>
        <h1 className="mob">
          I&apos;m <span>Jérémy</span>, web developer based in Paris, currently
          looking for a job.
        </h1>
        <HoverImage
          page={'iam'}
          active={activeImg}
          xPos={activeImg ? xPos : '50%'}
          yPos={activeImg ? yPos : '50%'}
        />
      </div>
    </StyledIam>
  )
}
