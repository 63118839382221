import React from 'react'
import { StyledContactMe } from './ContactMe.styled'

export const ContactMe = () => {
  return (
    <StyledContactMe className="contactMe">
      <p>i&apos;m available</p>
      <div className="contact_me">
        <a href="mailto:jeremy.lichtmann@gmail.com" rel="noopener noreferrer">
          <p>contact me</p>
          <p>contact me</p>
          <p>contact me</p>
          <p>contact me</p>
        </a>
      </div>
    </StyledContactMe>
  )
}
