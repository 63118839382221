import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledAlice } from './Alice.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import phones from '../../Assets/Img/Alice/phones.webp'
import video1 from '../../Assets/Img/Alice/chargement.mp4'
import video2 from '../../Assets/Img/Alice/listeprojets.mp4'
import video3 from '../../Assets/Img/Alice/entree-projet.mp4'
import video4 from '../../Assets/Img/Alice/projet.mp4'
import video5 from '../../Assets/Img/Alice/about.mp4'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Alice = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledAlice>
        <ProjectNavbar />
        <IntroProject title={"Alice's portfolio"} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2022
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    Alice Jestin
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    URL
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    <a
                      href="https://alicejestin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      alicejestin.com
                    </a>
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno front
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    ReactJS
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno back
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    NodeJS
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              Creation of the portfolio of Alice jestin, DA freelance. Emphasis
              on animation and fluidity of navigation. Developed in ReactJS with
              use of GSAP and Framer Motion for animations. The authentication
              part is managed by a NodeJS backend.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <h2>Loader</h2>
          <div className="image">
            <video
              src={video1}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>Homepage</h2>
          <div className="image">
            <video
              src={video2}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>Project Page</h2>
          <div className="image mb">
            <video
              src={video3}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="image">
            <video
              src={video4}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>About Page</h2>
          <div className="image mb">
            <video
              src={video5}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>Mobile</h2>
          <div className="fullImage">
            <img src={phones} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledAlice>
    </ThemeProvider>
  )
}
