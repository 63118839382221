import styled from 'styled-components'

export const StyledIam = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    margin: 150px auto;
  }

  .image-iam {
    position: relative;
    z-index: 0;
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }

    .center {
      position: relative;
      z-index: 2;
      width: 75px;
      left: -4px;

      @media (max-width: 768px) {
        width: 60px;
      }
    }
    .bg {
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
      left: 0;
      animation: rotateImage 10s infinite linear;
    }
  }
  @keyframes rotateImage {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      padding: 0 50px;
    }
    @media (max-width: 767px) {
      padding: 0 20px;
    }

    .desk {
      display: block;

      @media (max-width: 1024px) {
        display: none;
      }
    }
    .mob {
      display: none;

      @media (max-width: 1024px) {
        display: block;
      }
    }

    .first-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    h1 {
      font-family: 'Monument', sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      max-width: 1210px;
      line-height: 120%;

      @media (max-width: 1350px) {
        font-size: 45px;
      }

      span {
        width: fit-content;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 0;
      }

      @media (max-width: 1024px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
    .gradient {
      position: relative;
      width: fit-content;
      overflow: hidden;
      cursor: pointer;

      h1 {
        width: fit-content;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 0;
      }
      .line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6px;
        background: -webkit-linear-gradient(
          -15deg,
          rgb(29, 3, 108),
          rgb(0, 222, 199)
        );
        transition: all 0.3s ease-in-out;
        transform: translateX(-105%);
      }
      @media (min-width: 1023px) {
        &:hover {
          .line {
            transform: translateX(0);
          }
        }
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`
