import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledAganippe } from './Aganippe.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import video1 from '../../Assets/Img/aganippe/Intro_site.mp4'
import video2 from '../../Assets/Img/aganippe/menu.mp4'
import Img1 from '../../Assets/Img/aganippe/aganippe-1.webp'
import Img2 from '../../Assets/Img/aganippe/aganippe-2.webp'
import Img3 from '../../Assets/Img/aganippe/aganippe-3.webp'
import Img4 from '../../Assets/Img/aganippe/aganippe-4.webp'
import Img5 from '../../Assets/Img/aganippe/aganippe-5.webp'
import Img6 from '../../Assets/Img/aganippe/aganippe-6.webp'
import Img7 from '../../Assets/Img/aganippe/aganippe-7.webp'
import Img8 from '../../Assets/Img/aganippe/aganippe-8.webp'
import Img9 from '../../Assets/Img/aganippe/aganippe-9.webp'
import Img10 from '../../Assets/Img/aganippe/aganippe-10.webp'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Aganippe = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledAganippe>
        <ProjectNavbar />
        <IntroProject title={'Aganippe'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2022
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    Art Association
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    URL
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    / /
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno front
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    NextJS
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    Headless CMS
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    Strapi
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.5, ...transition },
                    }}
                  >
                    Database
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.5, ...transition },
                    }}
                  >
                    MySQL 5.6
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              Creation of a website for an event association around art. This
              website was developed with Next.js and is based on Strapi an
              open-source headless CMS coupled to a MySQL database.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <h2>Homepage</h2>
          <div className="image">
            <video
              src={video1}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="image mt-aganippe">
            <img src={Img1} alt="bonhomme" />
          </div>
          <h2>Menu</h2>
          <div className="multi">
            <div className="big">
              <video
                src={video2}
                autoPlay
                loop
                muted
                playsInline
                type="video/mp4"
              ></video>
            </div>
            <div className="small">
              <img src={Img2} alt="bonhomme" />
            </div>
          </div>
          <h2>About</h2>
          <div className="multi">
            <div className="big">
              <img src={Img3} alt="bonhomme" />
            </div>
            <div className="small">
              <img src={Img4} alt="bonhomme" />
            </div>
          </div>
          <h2>Artist&apos;s page</h2>
          <div className="multi">
            <div className="big">
              <img src={Img5} alt="bonhomme" />
            </div>
            <div className="small">
              <img src={Img6} alt="bonhomme" />
            </div>
          </div>
          <h2>Artists list</h2>
          <div className="image">
            <img src={Img7} alt="bonhomme" />
          </div>
          <h2>Events list</h2>
          <div className="multi">
            <div className="small">
              <img src={Img8} alt="bonhomme" />
            </div>
            <div className="big">
              <img src={Img9} alt="bonhomme" />
            </div>
          </div>
          <h2>Event&apos;s page</h2>
          <div className="image">
            <img src={Img10} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledAganippe>
    </ThemeProvider>
  )
}
