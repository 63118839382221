import styled from 'styled-components'

export const StyledHand = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  bottom: 15px;
  right: 45%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    display: none;
  }

  p {
    font-size: 16px;
    margin-right: 30px;
    font-family: 'AvenirBook', sans-serif;
  }
  .imageHand {
    width: 70px;
    height: auto;
    margin: 0;
    //transform: translateX(-15px);
    animation: animHand 1s infinite ease-in-out alternate;
  }
  img {
    width: 100%;
    height: auto;
  }
  @keyframes animHand {
    from {
      transform: translateX(-15px);
    }
    to {
      transform: translateX(15px);
    }
  }
`
