import styled from 'styled-components'

export const StyledIntro = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15vh;

  .image {
    position: relative;
    z-index: 5;
    width: 250px;
    height: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 768px) {
      width: 250px;
      height: 250px;
    }

    img {
      height: 100% !important;
      width: auto !important;
    }
  }

  .text {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;

    @media (max-width: 768px) {
      align-items: center;
    }

    .links {
      position: absolute;
      border: 1px solid;
      border-radius: 15px;
      padding: 6px 15px 4px 15px;

      @media (max-width: 1024px) {
        padding: 2px 7px 2px 7px;
      }
      @media (max-width: 768px) {
        padding: 3px 7px 1px 7px;
      }

      p {
        font-family: 'Avenir', sans-serif;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;

        @media (max-width: 1024px) {
          font-size: 12px;
        }
        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
    }
    .skills {
      top: -12px;
      right: 39.5%;

      @media (max-width: 1024px) {
        top: -8px;
      }
      @media (max-width: 768px) {
        right: 28%;
      }
      @media (max-width: 450px) {
        top: -10px;
        right: 22%;
      }
    }
    .contact {
      bottom: -8px;
      right: 13%;

      @media (max-width: 1024px) {
        bottom: -6px;
      }
      @media (max-width: 450px) {
        bottom: -8px;
        right: 8%;
      }
    }
    h1 {
      font-family: 'Monument', sans-serif;
      font-size: 70px;
      font-style: normal;
      font-weight: normal;
      margin-left: 100px;

      @media (max-width: 1024px) {
        font-size: 50px;
      }
      @media (max-width: 768px) {
        font-size: 35px;
        margin-left: 0;
      }
      @media (max-width: 450px) {
        font-size: 25px;
      }
    }
    .gradient {
      position: relative;
      width: fit-content;
      overflow: hidden;
      cursor: pointer;
      h1 {
        width: fit-content;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 0;
      }
      .line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 6px;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        transition: all 0.3s ease-in-out;
        transform: translateX(-105%);
      }
      @media (min-width: 1023px) {
        &:hover {
          .line {
            transform: translateX(0);
          }
        }
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`
