import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledEmailingManager } from './EmailingManager.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import Img1 from '../../Assets/Img/EM/img-1.webp'
import Img2 from '../../Assets/Img/EM/img-2.webp'
import Img3 from '../../Assets/Img/EM/img-3.webp'
import Img4 from '../../Assets/Img/EM/img-4.webp'
import Img5 from '../../Assets/Img/EM/img-5.webp'
import Img6 from '../../Assets/Img/EM/img-6.webp'
import Img7 from '../../Assets/Img/EM/img-7.webp'
import video from '../../Assets/Img/EM/video.mp4'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const EmailingManager = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledEmailingManager>
        <ProjectNavbar />
        <IntroProject title={'Emailing Manager'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2021
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    360º Fahrenheit
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Techno front
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    ReactJS
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    Techno back
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.3, ...transition },
                    }}
                  >
                    NodeJS
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              Creation of an internal tool to facilitate the creation and
              management of emails to integrate. These emails will then be
              delivered to the customers for the purpose of email campaign or
              other. It allows you to integrate them in a few clicks, send them,
              download the source files, ...
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <h2>Login page</h2>
          <p>
            Each employee of the agency has his own account in order to have a
            personal space in which he has access to all the emails, on which he
            has worked.
          </p>
          <div className="image">
            <img src={Img1} alt="bonhomme" />
          </div>
          <h2>Dashboard</h2>
          <p>
            Once signed, the employee arrives on a dashboard containing all the
            emails that were created. They can choose to display only their own
            or those of the entire company. Each email contains the following
            informations: campaign name, city, developer name, status (in
            design, internal BAT, customer BAT with the number of BAT sent, the
            creation date, its author, the last update date with the name of the
            person who made this update). The email can be archived (in the case
            of a completed campaign), duplicated or deleted.
          </p>
          <div className="image mb">
            <img src={Img2} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img3} alt="bonhomme" />
          </div>
          <h2>Email creation form</h2>
          <p>
            When creating a new email, information are needed, they will be
            directly injected into the source code of the mail. The choice of a
            template is also possible in order to best match the customer’s
            expectations.
          </p>
          <div className="image">
            <img src={Img4} alt="bonhomme" />
          </div>
          <h2>Email composition page</h2>
          <p>
            Once the email is created, the different parts will be inserted.
            Using an existing block library, the user has the option to select
            them and insert them directly into the code at the desired location.
            Once all the blocks are present, the mail will be builded. The next
            step is to modify texts, styles and assets while having the
            possibility to view the rendering of the mail in real time.
          </p>
          <div className="image">
            <video
              src={video}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <h2>Email management</h2>
          <p>
            Once the email is completed, the user has the possibility to send it
            to different addresses (internally or to the customer), to send it
            to EmailOnAcid for testing or to download it as a zip.
          </p>
          <div className="image mb">
            <img src={Img5} alt="bonhomme" />
          </div>
          <div className="image mb">
            <img src={Img6} alt="bonhomme" />
          </div>
          <div className="image">
            <img src={Img7} alt="bonhomme" />
          </div>
        </motion.div>
      </StyledEmailingManager>
    </ThemeProvider>
  )
}
