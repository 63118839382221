import styled from 'styled-components'

export const StyledIntroProject = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 250px 0;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 150px 0;
  }
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .image {
      width: 115px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        width: 90px;
      }
    }

    .portfolio {
      p {
        font-family: 'Monument', sans-serif;
        font-size: 23px;
        background-image: linear-gradient(
          98deg,
          #7673eb 9%,
          #77c0de 60%,
          #78fad3 93%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
      }
    }

    h1 {
      font-family: 'Monument', sans-serif;
      font-size: 60px;
      margin-top: 30px;

      @media (max-width: 1024px) {
        font-size: 50px;
        max-width: 90%;
        text-align: center;
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`
