import React, { useState, useRef, useMemo } from 'react'
import { throttle } from 'lodash'
import { StyledIntro } from './Intro.styled'
import { Blur } from '../../Components/Blur/Blur'
import { Hand } from '../../Components/Hand/Hand'
import { HoverImage } from '../../Components/HoverImage/HoverImage'

//import bonhomme from '../../Assets/Img/bonhomme.svg'
import me from '../../Assets/Img/me.png'

/*const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const updateMousePosition = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener('mousemove', updateMousePosition)

    return () => window.removeEventListener('mousemove', updateMousePosition)
  })
  return mousePosition
}*/
export const Intro = ({ handleNav }) => {
  const [activeImg, setActiveImg] = useState(false)
  const [xPos, setXPos] = useState(0)
  const [yPos, setYPos] = useState(0)
  const link5 = useRef()
  const link6 = useRef()
  //const { x, y } = useMousePosition()

  /*useEffect(() => {
    const updateMousePosition = (e) => {
      setXPos(e.clientX)
      setYPos(e.clientY)
    }
    window.addEventListener('mousemove', updateMousePosition)
    console.log(yPos, yPos)
    return () => window.removeEventListener('mousemove', updateMousePosition)
  })*/
  const showImg = useRef()

  const handleMouse = useMemo(() => {
    const throttled = throttle((e) => {
      setXPos(e.clientX)
      setYPos(e.clientY)
    }, 100)
    return (e) => {
      e.persist()
      return throttled(e)
    }
  }, [])
  return (
    <StyledIntro className="Intro">
      <Blur position={'left'} />
      <Hand />
      <div className="container-intro">
        <div className="image">
          <img src={me} alt="bonhomme" />
        </div>
        <div className="text">
          <div className="links skills">
            <p ref={link5} onClick={() => handleNav(link5)}>
              my skills
            </p>
          </div>
          <div className="links contact">
            <p ref={link6} onClick={() => handleNav(link6)}>
              contact me
            </p>
          </div>
          <h1>hey, i&apos;m a</h1>
          <div className="gradient">
            <h1
              onMouseMove={handleMouse}
              onMouseEnter={() => setActiveImg(true)}
              onMouseLeave={() => setActiveImg(false)}
              ref={showImg}
            >
              Web developer
            </h1>
            <div className="line"></div>
          </div>
          <h1>Open to work</h1>
          <HoverImage
            page={'intro'}
            active={activeImg}
            xPos={activeImg ? xPos : '50%'}
            yPos={activeImg ? yPos : '50%'}
          />
        </div>
      </div>
    </StyledIntro>
  )
}
