import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import {
  Home,
  Aganippe,
  Alice,
  Thetiptop,
  EmailingManager,
  Beclou,
  Fahrenheit,
  RealEstates,
} from './Pages/index'

const App = () => {
  const location = useLocation()
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Routes location={location} key={location.key}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Aganippe" element={<Aganippe />} />
        <Route exact path="/AlicesPortfolio" element={<Alice />} />
        <Route exact path="/TheTipTop" element={<Thetiptop />} />
        <Route exact path="/EmailingManager" element={<EmailingManager />} />
        <Route exact path="/Beclou" element={<Beclou />} />
        <Route exact path="/360Fahrenheit" element={<Fahrenheit />} />
        <Route exact path="/RealEstates" element={<RealEstates />} />
      </Routes>
    </AnimatePresence>
  )
}

export default App
