import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { StyledFahrenheit } from './Fahrenheit.styled'
import { ProjectNavbar } from '../../Components/ProjectNavbar/ProjectNavbar'
import { IntroProject } from '../../Components/IntroProject/IntroProject'
import { GlobalStyles, lightTheme, darkTheme } from '../../Hooks/globalStyles'
import { ToggleDarkMode } from '../../Components/ToggleDarkMode/ToggleDarkMode'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import img1 from '../../Assets/Img/fahrenheit/img-1.webp'
import img2 from '../../Assets/Img/fahrenheit/img-2.png'
import video1 from '../../Assets/Img/fahrenheit/video-1.mp4'
import video2 from '../../Assets/Img/fahrenheit/video-2.mp4'
import video3 from '../../Assets/Img/fahrenheit/video-3.mp4'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Fahrenheit = () => {
  const [colorTheme, setColorTheme] = useState('')
  const themeMode = colorTheme === 'light' ? lightTheme : darkTheme
  const { pathname } = useLocation()
  const sendToHome = (data) => {
    setColorTheme(data)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ThemeProvider theme={themeMode}>
      <ToggleDarkMode sendToHome={sendToHome} />
      <GlobalStyles />
      <StyledFahrenheit>
        <ProjectNavbar />
        <IntroProject title={'360º Fahrenheit'} />
        <div className="Description-project">
          <div className="titles">
            <table>
              <tbody>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    Year
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.5, ...transition },
                    }}
                  >
                    2021
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    For
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.7, ...transition },
                    }}
                  >
                    360º Fahrenheit
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    URL
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.9, ...transition },
                    }}
                  >
                    <a
                      href="https://www.3cent60.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3cent60.net
                    </a>
                  </motion.td>
                </tr>
                <tr>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 0.6,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    Technos
                  </motion.td>
                  <motion.td
                    initial={{
                      opacity: 0,
                      y: 60,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.1, ...transition },
                    }}
                  >
                    PHP, Javascript
                  </motion.td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="description">
            <motion.p
              initial={{
                opacity: 0,
                x: 150,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 1.9, ...transition },
              }}
            >
              Development of the new 360º Fahrenheit’s website.
            </motion.p>
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            y: 150,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 2.3, ...transition },
          }}
          className="Content-project"
        >
          <div className="imageMed mb-med mt-big">
            <video
              src={video1}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="imageMed mb-med">
            <video
              src={video2}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="imageMed mb-big">
            <video
              src={video3}
              autoPlay
              loop
              muted
              playsInline
              type="video/mp4"
            ></video>
          </div>
          <div className="multi">
            <div className="big">
              <img src={img1} alt="bonhomme" />
            </div>
            <div className="small">
              <img src={img2} alt="bonhomme" />
            </div>
          </div>
        </motion.div>
      </StyledFahrenheit>
    </ThemeProvider>
  )
}
